import { Box, ClickAwayListener, Grid, IconButton, Link, Paper, Popper, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import AppsIcon from '@mui/icons-material/Apps';

export default function AppsDialog(): React.ReactElement {
    const [allAppsDialogOpened, setAllAppsDialogOpened] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const showAllAppsDialog = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setAllAppsDialogOpened(true);
    };

    const clickAwayHandler = () => {
        setAllAppsDialogOpened(false);
    };

    return (
        <>
            <Tooltip title="Aplikacije">
                <IconButton sx={{ display: { xs: 'flex' }, color: 'inherit' }} onClick={showAllAppsDialog}>
                    <AppsIcon sx={{ color: 'inherit' }} />
                </IconButton>
            </Tooltip>
            {allAppsDialogOpened && (
                <ClickAwayListener onClickAway={clickAwayHandler}>
                    <Popper open={allAppsDialogOpened} anchorEl={anchorEl} sx={{ zIndex: 1500 }}>
                        <Paper sx={{ padding: 2, paddingLeft: 3 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Link href={'https://hrweb.granitdoo.rs'} target={'_blank'} underline="none">
                                        <Box
                                            component="img"
                                            marginLeft={1.2}
                                            sx={{
                                                height: 30,
                                                width: 30,
                                            }}
                                            alt="Armatura icon"
                                            src="/favicon.svg"
                                        />
                                        <Typography variant="body2">Hr Web</Typography>
                                    </Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link href={'https://arm.granitdoo.rs'} target={'_blank'} underline="none">
                                        <Box
                                            component="img"
                                            marginLeft={1.2}
                                            sx={{
                                                height: 30,
                                                width: 30,
                                            }}
                                            alt="Armatura icon"
                                            src="/armicon.png"
                                        />
                                        <Typography variant="body2">Armature</Typography>
                                    </Link>
                                </Grid>
                                <Grid item xs={6}>
                                    <Link href={'https://app.granitdoo.rs'} target={'_blank'} underline="none">
                                        <Box
                                            component="img"
                                            marginLeft={1.2}
                                            sx={{
                                                height: 30,
                                                width: 30,
                                            }}
                                            alt="Armatura icon"
                                            src="/graniticon.ico"
                                        />
                                        <Typography variant="body2">Transport</Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Popper>
                </ClickAwayListener>
            )}
        </>
    );
}
