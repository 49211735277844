import { ResponseState } from '../Extensions/Types';
import { CompleteUserInfoDto } from '../Extensions/UserOnCreation';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface IMSGraphClient {
    /**
     * @return Success
     */
    getUserById(userId: string): Promise<GraphUserDto>;
    getUserCompleteInfo(userId: string): Promise<CompleteUserInfoDto>;
    getAllUsers(): Promise<GraphUserDto[]>;
    getAllUnlinkedUsers(): Promise<GraphUserManagerDto[]>;
    getUserPhoto(userId: string): Promise<Blob | MediaSource | undefined>;
    updateUserProfilePhoto(userId: string, profileImg: Blob): Promise<ResponseState>;
    getMe(): Promise<GraphUserDto>;
    getUsersDirectReports(id: string): Promise<GraphUserDto[]>;
    getUsersManager(id: string): Promise<GraphUserDto>;
    getUsersManagerChain(id: string): Promise<GraphUserManagerDto>;
    addNewUser(body: any): Promise<GraphUserDto>;
    assignManager(userId: string, managerId: string): Promise<ResponseState>;
    updateUser(userId: string, body: any): Promise<ResponseState>;
    deleteUser(userId: string): Promise<ResponseState>;
    checkUserPrincipalAvailability(userPrincipal: string): Promise<boolean>;
    addMemberToGroup(userId: string, groupId: string): Promise<ResponseState>;
    removeMemberFromGroup(userId: string, groupId: string): Promise<ResponseState>;
    getServicePrincipals(): Promise<ServicePrincipalDto[]>;
    assignAppRole(userId: string, appRoleAssignment: AppRoleAssignmentDto): Promise<ResponseState>;
    getUserAppRoles(userId: string): Promise<AppRoleAssignmentDto[]>;
    removeAppRole(userId: string, appRoleAssignmentId: string): Promise<ResponseState>;
    resetUsersPassword(userId: string, newPass: string): Promise<ResponseState>;
    getUserPasswordProfile(userId: string): Promise<PasswordProfileDto>;
}

export class MSGraphClient implements IMSGraphClient {
    private http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) {
        this.http = http ? http : (window as any);
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    }

    getMe(): Promise<GraphUserDto> {
        let url_ = this.baseUrl + '/me';
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetMe(_response);
        });
    }

    protected processgetMe(response: Response): Promise<GraphUserDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = GraphUserDto.fromJS(resultData200);
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUsersDirectReports(id: string): Promise<GraphUserDto[]> {
        let url_ = this.baseUrl + '/users/' + id + '/directReports';
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUsersDirectReports(_response);
        });
    }

    protected processgetUsersDirectReports(response: Response): Promise<GraphUserDto[]> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: GraphUserDto[] = [];
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                if (Array.isArray(resultData200.value)) {
                    for (const emp of resultData200.value) result200.push(GraphUserDto.fromJS(emp));
                } else {
                    result200 = <any>null;
                }
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUsersManager(id: string): Promise<GraphUserDto> {
        let url_ = this.baseUrl + '/users/' + id + '/manager';
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUsersManager(_response);
        });
    }

    protected processgetUsersManager(response: Response): Promise<GraphUserDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = GraphUserDto.fromJS(resultData200);
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUserById(userId: string): Promise<GraphUserDto> {
        const select =
            '?$select=displayName,id,givenName,surname,mail,jobTitle,mobilePhone,department,userPrincipalName';
        let url_ = this.baseUrl + '/users/' + userId + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUserById(_response);
        });
    }

    protected processgetUserById(response: Response): Promise<GraphUserDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = GraphUserDto.fromJS(resultData200);
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUserCompleteInfo(userId: string): Promise<CompleteUserInfoDto> {
        const select =
            '?$select=id,displayName,givenName,surname,mailNickname,userPrincipalName,businessPhones,city,companyName,country,department,employeeHireDate,jobTitle,mail,mobilePhone,officeLocation,streetAddress,onPremisesExtensionAttributes';
        let url_ = this.baseUrl + '/users/' + userId + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUserCompleteInfo(_response);
        });
    }

    protected processgetUserCompleteInfo(response: Response): Promise<CompleteUserInfoDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = CompleteUserInfoDto.fromJS(resultData200);
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getAllUsers(): Promise<GraphUserDto[]> {
        const select = '?$select=id';
        let url_ = this.baseUrl + '/users' + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetAllUsers(_response);
        });
    }

    protected processgetAllUsers(response: Response): Promise<GraphUserDto[]> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: GraphUserDto[] = [];
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                if (Array.isArray(resultData200.value)) {
                    for (const emp of resultData200.value) result200.push(GraphUserDto.fromJS(emp));
                } else {
                    result200 = <any>null;
                }
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getAllUnlinkedUsers(): Promise<GraphUserManagerDto[]> {
        const select = `?$select=id,jobTitle&$expand=manager($select=id)`;
        let url_ = this.baseUrl + '/users' + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                ConsistencyLevel: 'eventual',
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetAllUnlinkedUsers(_response);
        });
    }

    protected processgetAllUnlinkedUsers(response: Response): Promise<GraphUserManagerDto[]> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: GraphUserManagerDto[] = [];
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                if (Array.isArray(resultData200.value)) {
                    for (const emp of resultData200.value) result200.push(GraphUserManagerDto.fromJS(emp));
                } else {
                    result200 = <any>null;
                }
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUserPhoto(userId: string): Promise<Blob | MediaSource | undefined> {
        const select = '/photo/$value';
        let url_ = this.baseUrl + '/users/' + userId + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUserPhoto(_response);
        });
    }

    protected processgetUserPhoto(response: Response): Promise<Blob | MediaSource | undefined> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.blob();
        } else if (status === 404) {
            return response.text().then(() => {
                return undefined;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    updateUserProfilePhoto(userId: string, profileImg: Blob): Promise<ResponseState> {
        const select = '/photo/$value';
        let url_ = this.baseUrl + '/users/' + userId + select;
        url_ = url_.replace(/[?&]$/, '');

        // const cont = getBase64(profileImg);

        const options_: RequestInit = {
            body: profileImg,
            method: 'PATCH',
            headers: {
                'Content-type': 'image/jpeg',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processupdateUserProfilePhoto(_response);
        });
    }

    protected processupdateUserProfilePhoto(response: Response): Promise<ResponseState> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then(() => {
                return ResponseState.Success;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUsersManagerChain(id: string): Promise<GraphUserManagerDto> {
        let url_ =
            this.baseUrl +
            '/users/' +
            id +
            '/?$expand=manager($levels=max;$select=id,displayName,jobTitle,manager)&$count=true';
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                ConsistencyLevel: 'eventual',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUsersManagerChain(_response);
        });
    }

    protected processgetUsersManagerChain(response: Response): Promise<GraphUserManagerDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = GraphUserManagerDto.fromJS(resultData200);
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    checkUserPrincipalAvailability(userPrincipal: string): Promise<boolean> {
        const select = `?$count=true&$filter=userPrincipalName eq '${userPrincipal}'`;
        let url_ = this.baseUrl + '/users' + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                ConsistencyLevel: 'eventual',
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processcheckUserPrincipalAvailability(_response);
        });
    }

    protected processcheckUserPrincipalAvailability(response: Response): Promise<boolean> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                if (Array.isArray(resultData200.value)) {
                    if (resultData200.value.length > 0) {
                        return false;
                    }
                } else {
                    return true;
                }
                return true;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    /**
     * @param body (optional)
     * @return Success
     */
    addNewUser(body: any): Promise<GraphUserDto> {
        let url_ = this.baseUrl + '/users';
        url_ = url_.replace(/[?&]$/, '');

        const content_ = JSON.stringify(body);

        const options_: RequestInit = {
            body: content_,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processAddNewUser(_response);
        });
    }

    protected processAddNewUser(response: Response): Promise<GraphUserDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 201) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = GraphUserDto.fromJS(resultData200);
                return result200;
            });
        } else if (status === 400) {
            return response.text().then((_responseText) => {
                let result400: any = null;
                const resultData400 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result400 = ProblemDetails.fromJS(resultData400);
                return throwException('Bad Request', status, _responseText, _headers, result400);
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    /**
     * @param body (optional)
     * @return Success
     */
    updateUser(userId: string, body: any): Promise<ResponseState> {
        let url_ = this.baseUrl + '/users/' + userId;
        url_ = url_.replace(/[?&]$/, '');

        const content_ = JSON.stringify(body);

        const options_: RequestInit = {
            body: content_,
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processUpdateUser(_response);
        });
    }

    protected processUpdateUser(response: Response): Promise<ResponseState> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 204) {
            return response.text().then(() => {
                return ResponseState.Success;
            });
        } else if (status === 400) {
            return response.text().then(() => {
                return ResponseState.Error;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    /**
     * @param body (optional)
     * @return Success
     */
    deleteUser(userId: string): Promise<ResponseState> {
        let url_ = this.baseUrl + '/users/' + userId;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processDeleteUser(_response);
        });
    }

    protected processDeleteUser(response: Response): Promise<ResponseState> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 204) {
            return response.text().then(() => {
                return ResponseState.Success;
            });
        } else if (status === 400) {
            return response.text().then(() => {
                return ResponseState.Error;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    addMemberToGroup(userId: string, groupId: string): Promise<ResponseState> {
        let url_ = this.baseUrl + '/groups/' + groupId + '/members/$ref';
        url_ = url_.replace(/[?&]$/, '');

        const directoryObject = {
            '@odata.id': 'https://graph.microsoft.com/v1.0/users/' + userId,
        };

        const content_ = JSON.stringify(directoryObject);

        const options_: RequestInit = {
            body: content_,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processaddMemberToGroup(_response);
        });
    }

    protected processaddMemberToGroup(response: Response): Promise<ResponseState> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 204) {
            return response.text().then(() => {
                return ResponseState.Success;
            });
        } else if (status === 400) {
            return response.text().then(() => {
                return ResponseState.Error;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    removeMemberFromGroup(userId: string, groupId: string): Promise<ResponseState> {
        let url_ = this.baseUrl + '/groups/' + groupId + '/members/' + userId + '/$ref';
        url_ = url_.replace(/[?&]$/, '');

        const directoryObject = {
            '@odata.id': 'https://graph.microsoft.com/v1.0/users/' + userId,
        };

        const content_ = JSON.stringify(directoryObject);

        const options_: RequestInit = {
            body: content_,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processremoveMemberFromGroup(_response);
        });
    }

    protected processremoveMemberFromGroup(response: Response): Promise<ResponseState> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 204) {
            return response.text().then(() => {
                return ResponseState.Success;
            });
        } else if (status === 400) {
            return response.text().then(() => {
                return ResponseState.Error;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getServicePrincipals(): Promise<ServicePrincipalDto[]> {
        const select = '?$select=id,appDisplayName,appRoles';
        let url_ = this.baseUrl + '/servicePrincipals' + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ConsistencyLevel: 'eventual',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetServicePrincipals(_response);
        });
    }

    protected processgetServicePrincipals(response: Response): Promise<ServicePrincipalDto[]> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: ServicePrincipalDto[] = [];
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                if (Array.isArray(resultData200.value)) {
                    for (const emp of resultData200.value) result200.push(ServicePrincipalDto.fromJS(emp));
                } else {
                    result200 = <any>null;
                }
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    assignAppRole(userId: string, appRoleAssignment: IAppRoleAssignmentDto): Promise<ResponseState> {
        let url_ = this.baseUrl + '/users/' + userId + '/appRoleAssignments';
        url_ = url_.replace(/[?&]$/, '');

        const directoryObject = {
            principalId: appRoleAssignment.principalId,
            appRoleId: appRoleAssignment.appRoleId,
            resourceId: appRoleAssignment.resourceId,
        };

        const content_ = JSON.stringify(directoryObject);

        const options_: RequestInit = {
            body: content_,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processassignAppRole(_response);
        });
    }

    protected processassignAppRole(response: Response): Promise<ResponseState> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 201 || status === 200) {
            return response.text().then(() => {
                return ResponseState.Success;
            });
        } else if (status === 400) {
            return response.text().then(() => {
                return ResponseState.Error;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUserAppRoles(userId: string): Promise<AppRoleAssignmentDto[]> {
        let url_ = this.baseUrl + '/users/' + userId + '/appRoleAssignments';
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUserAppRoles(_response);
        });
    }

    protected processgetUserAppRoles(response: Response): Promise<AppRoleAssignmentDto[]> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: AppRoleAssignmentDto[] = [];
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                if (Array.isArray(resultData200.value)) {
                    for (const emp of resultData200.value) result200.push(AppRoleAssignmentDto.fromJS(emp));
                } else {
                    result200 = <any>null;
                }
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    removeAppRole(userId: string, appRoleAssignmentId: string): Promise<ResponseState> {
        let url_ = this.baseUrl + '/users/' + userId + '/appRoleAssignments/' + appRoleAssignmentId;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processremoveAppRole(_response);
        });
    }

    protected processremoveAppRole(response: Response): Promise<ResponseState> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 201 || status === 200 || status === 204) {
            return response.text().then(() => {
                return ResponseState.Success;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    /**
     * @param body (optional)
     * @return Success
     */
    assignManager(userId: string, managerId: string): Promise<ResponseState> {
        let url_ = this.baseUrl + '/users/' + userId + '/manager/$ref';
        url_ = url_.replace(/[?&]$/, '');

        const directoryObject = {
            '@odata.id': 'https://graph.microsoft.com/v1.0/users/' + managerId,
        };

        const content_ = JSON.stringify(directoryObject);

        const options_: RequestInit = {
            body: content_,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processAssignManager(_response);
        });
    }

    protected processAssignManager(response: Response): Promise<ResponseState> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 204 || status === 201 || status === 200) {
            return response.text().then(() => {
                return ResponseState.Success;
            });
        } else if (status === 400) {
            return response.text().then(() => {
                return ResponseState.Error;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    resetUsersPassword(userId: string, newPass: string): Promise<ResponseState> {
        let url_ = this.baseUrl + '/users/' + userId;
        url_ = url_.replace(/[?&]$/, '');

        const body = {
            passwordProfile: {
                forceChangePasswordNextSignIn: true,
                password: newPass,
            },
        };

        const content_ = JSON.stringify(body);

        const options_: RequestInit = {
            body: content_,
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processresetUsersPassword(_response);
        });
    }

    protected processresetUsersPassword(response: Response): Promise<ResponseState> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }
        if (status === 204 || status === 200) {
            return response.text().then(() => {
                return ResponseState.Success;
            });
        } else if (status === 400) {
            return response.text().then(() => {
                return ResponseState.Error;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }

    getUserPasswordProfile(userId: string): Promise<PasswordProfileDto> {
        const select = '?$select=id,passwordProfile,userPrincipalName';
        let url_ = this.baseUrl + '/users/' + userId + select;
        url_ = url_.replace(/[?&]$/, '');

        const options_: RequestInit = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processgetUserPasswordProfile(_response);
        });
    }

    protected processgetUserPasswordProfile(response: Response): Promise<PasswordProfileDto> {
        const status = response.status;
        const _headers: any = {};
        if (response.headers && response.headers.forEach) {
            response.headers.forEach((v: any, k: any) => (_headers[k] = v));
        }

        if (status === 200) {
            return response.text().then((_responseText) => {
                let result200: any = null;
                const resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result200 = PasswordProfileDto.fromJS(resultData200);
                return result200;
            });
        } else {
            return response.text().then((_responseText) => {
                let resultdefault: any = null;
                const resultDatadefault =
                    _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
                resultdefault = ProblemDetails.fromJS(resultDatadefault);
                return throwException('Error', status, _responseText, _headers, resultdefault);
            });
        }
    }
}

export interface IGraphUserDto {
    id: string | undefined;
    displayName: string | undefined;
    givenName: string | undefined;
    jobTitle: string | undefined;
    mail: string | undefined;
    mobilePhone: string | undefined;
    surname: string | undefined;
    department: string | undefined;
    userPrincipalName: string | undefined;
}

export class GraphUserDto implements IGraphUserDto {
    id!: string | undefined;
    displayName!: string | undefined;
    givenName!: string | undefined;
    jobTitle!: string | undefined;
    mail!: string | undefined;
    mobilePhone!: string | undefined;
    surname!: string | undefined;
    department!: string | undefined;
    userPrincipalName!: string | undefined;

    constructor(data?: IGraphUserDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.id = _data['id'];
            this.displayName = _data['displayName'];
            this.givenName = _data['givenName'];
            this.jobTitle = _data['jobTitle'];
            this.mail = _data['mail'];
            this.mobilePhone = _data['mobilePhone'];
            this.surname = _data['surname'];
            this.department = _data['department'];
            this.userPrincipalName = _data['userPrincipalName'];
        }
    }

    static fromJS(data: any): GraphUserDto {
        data = typeof data === 'object' ? data : {};
        const result = new GraphUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['id'] = this.id;
        data['displayName'] = this.displayName;
        data['givenName'] = this.givenName;
        data['jobTitle'] = this.jobTitle;
        data['mail'] = this.mail;
        data['mobilePhone'] = this.mobilePhone;
        data['surname'] = this.surname;
        data['department'] = this.department;
        data['userPrincipalName'] = this.userPrincipalName;

        return data;
    }
}

export interface IGraphUserManagerDto {
    id: string | undefined;
    displayName: string | undefined;
    jobTitle: string | undefined;
    photo: Blob | MediaSource | undefined;
    manager: IGraphUserManagerDto | undefined;
}

export class GraphUserManagerDto implements IGraphUserManagerDto {
    id!: string | undefined;
    displayName!: string | undefined;
    jobTitle!: string | undefined;
    photo!: Blob | MediaSource | undefined;
    manager!: GraphUserManagerDto | undefined;

    constructor(data?: GraphUserManagerDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.id = _data['id'];
            this.displayName = _data['displayName'];
            this.jobTitle = _data['jobTitle'];
            this.photo = _data['photo'];
            this.manager = _data['manager'];
        }
    }

    static fromJS(data: any): GraphUserManagerDto {
        data = typeof data === 'object' ? data : {};
        const result = new GraphUserManagerDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['id'] = this.id;
        data['displayName'] = this.displayName;
        data['jobTitle'] = this.jobTitle;
        data['photo'] = this.photo;
        data['manager'] = this.manager;

        return data;
    }
}

export class ProblemDetails implements IProblemDetails {
    type!: string | undefined;
    title!: string | undefined;
    status!: number | undefined;
    detail!: string | undefined;
    instance!: string | undefined;

    constructor(data?: IProblemDetails) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.type = _data['type'];
            this.title = _data['title'];
            this.status = _data['status'];
            this.detail = _data['detail'];
            this.instance = _data['instance'];
        }
    }

    static fromJS(data: any): ProblemDetails {
        data = typeof data === 'object' ? data : {};
        const result = new ProblemDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any): any {
        data = typeof data === 'object' ? data : {};
        data['type'] = this.type;
        data['title'] = this.title;
        data['status'] = this.status;
        data['detail'] = this.detail;
        data['instance'] = this.instance;
        return data;
    }
}

export interface IProblemDetails {
    type: string | undefined;
    title: string | undefined;
    status: number | undefined;
    detail: string | undefined;
    instance: string | undefined;
}

export class ApiException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

export interface IServicePrincipalDto {
    id: string | undefined;
    appDisplayName: string | undefined;
    appRoles: AppRoleDto[] | undefined;
}

export class ServicePrincipalDto implements IServicePrincipalDto {
    id!: string | undefined;
    appDisplayName!: string | undefined;
    appRoles!: AppRoleDto[] | undefined;

    constructor(data?: IServicePrincipalDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.id = _data['id'];
            this.appDisplayName = _data['appDisplayName'];
            this.appRoles = _data['appRoles'];
        }
    }

    static fromJS(data: any): ServicePrincipalDto {
        data = typeof data === 'object' ? data : {};
        const result = new ServicePrincipalDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['id'] = this.id;
        data['appDisplayName'] = this.appDisplayName;
        data['appRoles'] = this.appRoles;

        return data;
    }
}

export interface IAppRoleDto {
    id: string | undefined;
    displayName: string | undefined;
    value: string | undefined;
}

export class AppRoleDto implements IAppRoleDto {
    id!: string | undefined;
    displayName!: string | undefined;
    value!: string | undefined;

    constructor(data?: IAppRoleDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.id = _data['id'];
            this.displayName = _data['displayName'];
            this.value = _data['value'];
        }
    }

    static fromJS(data: any): AppRoleDto {
        data = typeof data === 'object' ? data : {};
        const result = new AppRoleDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['id'] = this.id;
        data['displayName'] = this.displayName;
        data['value'] = this.value;

        return data;
    }
}

export interface IAppRoleAssignmentDto {
    id: string | undefined;
    principalId: string | undefined;
    resourceId: string | undefined;
    appRoleId: string | undefined;
}

export class AppRoleAssignmentDto implements IAppRoleAssignmentDto {
    id!: string | undefined;
    principalId!: string | undefined;
    resourceId!: string | undefined;
    appRoleId!: string | undefined;

    constructor(data?: IAppRoleAssignmentDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.id = _data['id'];
            this.principalId = _data['principalId'];
            this.resourceId = _data['resourceId'];
            this.appRoleId = _data['appRoleId'];
        }
    }

    static fromJS(data: any): AppRoleAssignmentDto {
        data = typeof data === 'object' ? data : {};
        const result = new AppRoleAssignmentDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['id'] = this.id;
        data['resourceId'] = this.resourceId;
        data['principalId'] = this.principalId;
        data['appRoleId'] = this.appRoleId;

        return data;
    }
}

export interface IPasswordProfileDto {
    id: string | undefined;
    userPrincipalName: string | undefined;
    passwordProfile: { password: string | undefined };
}

export class PasswordProfileDto implements IPasswordProfileDto {
    id!: string | undefined;
    userPrincipalName!: string | undefined;
    passwordProfile!: { password: string | undefined };

    constructor(data?: IPasswordProfileDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.id = _data['id'];
            this.passwordProfile = _data['passwordProfile'];
            this.userPrincipalName = _data['userPrincipalName'];
        }
    }

    static fromJS(data: any): PasswordProfileDto {
        data = typeof data === 'object' ? data : {};
        const result = new PasswordProfileDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['id'] = this.id;
        data['passwordProfile'] = this.passwordProfile;
        data['userPrincipalName'] = this.userPrincipalName;

        return data;
    }
}

function throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any,
): any {
    if (result !== null && result !== undefined) throw result;
    else throw new ApiException(message, status, response, headers, null);
}
