import * as React from 'react';
import { Link } from 'react-router-dom';
import { Person, ViewType } from '@microsoft/mgt-react';

interface ICardProps {
    userId?: string;
    mgAuto: boolean;
    mgBottom: number;
}

export default function UserCard(props: ICardProps): React.ReactElement {
    return (
        <div
            style={{
                margin: props.mgAuto ? 'auto' : 'initial',
                marginBottom: props.mgAuto ? 'auto' : props.mgBottom,
                width: 230,
            }}
        >
            <Link to={`/employee/${props.userId}`} className="link">
                <div
                    style={{
                        height: '100%',
                        boxShadow: '2px 2px 4px #ccc, inset 3px 0px 0px #1976d2',
                        backgroundColor: 'white',
                        padding: 5,
                        paddingLeft: 8,
                        overflow: 'clip',
                    }}
                >
                    <Person
                        userId={props.userId}
                        view={ViewType.threelines}
                        line1Property={'displayName'}
                        line2Property={'jobTitle'}
                        line3Property={'department'}
                    />
                </div>
            </Link>
        </div>
    );
}
