import { msalInstance } from '../auth/AuthProvider';
import { protectedResources } from '../authConfig';
import { armBaseUrl, transportBaseUrl } from '../environments/environment';
import { IVNextClient, VNextArmClient, VNextClient } from './clientApi';

async function httpFetchVNext(url: RequestInfo, init?: RequestInit): Promise<Response> {
    if (init && init.headers) {
        const granitApiRequest = {
            ...{ scopes: protectedResources.granitApi.scopes },
        };

        const tokenPromise = msalInstance.acquireTokenSilent(granitApiRequest);
        const token = await tokenPromise;

        init.headers = {
            ...init?.headers,
            Authorization: 'Bearer ' + token.accessToken,
            'X-AuthSchema': 'AzureAD',
        };
    }

    return fetch(url, init);
}

async function httpFetchArmVNext(url: RequestInfo, init?: RequestInit): Promise<Response> {
    if (init && init.headers) {
        const granitApiRequest = {
            ...{ scopes: protectedResources.granitArmApi.scopes },
        };

        const tokenPromise = msalInstance.acquireTokenSilent(granitApiRequest);
        const token = await tokenPromise;

        init.headers = {
            ...init?.headers,
            Authorization: 'Bearer ' + token.accessToken,
            'X-AuthSchema': 'AzureAD',
        };
    }

    return fetch(url, init);
}

function GranitTransportClientApiVNext() {
    const clientApi = new VNextClient(transportBaseUrl, {
        fetch: httpFetchVNext,
    });

    return clientApi;
}

function GranitArmClientApiVNext() {
    const clientApi = new VNextArmClient(armBaseUrl, {
        fetch: httpFetchArmVNext,
    });

    return clientApi;
}

// urls are moved where scopes are defined

export const GranitTransportVNextClient: IVNextClient = GranitTransportClientApiVNext();
export const GranitArmVNextClient: IVNextClient = GranitArmClientApiVNext();
