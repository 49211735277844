import { combineReducers } from '@reduxjs/toolkit';
import privilegesReducer, { UserPrivilegesState } from './privilegesReducer';
import alertReducer, { AlertState } from './alertReducer';
import { currentEmployeeReducer, CurrentEmployeeState } from './currentEmployeeReducer';
import { loggedEmployeeReducer, LoggedEmployeeState } from './loggedEmployeeReducer';

export default combineReducers<RootState>({
    loggedEmployee: loggedEmployeeReducer,
    currentEmployee: currentEmployeeReducer,
    alerts: alertReducer,
    privileges: privilegesReducer,
});

export type RootState = {
    loggedEmployee: LoggedEmployeeState;
    currentEmployee: CurrentEmployeeState;
    alerts: AlertState;
    privileges: UserPrivilegesState;
};
