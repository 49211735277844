import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App/App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationResult, MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { msalInstance } from './auth/AuthProvider';
import { loginPopupRequest } from './auth/AuthConfig';
import { Provider } from 'react-redux';
import configureAppStore from './redux/store';

import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
Providers.globalProvider = new Msal2Provider({
    publicClientApplication: msalInstance,
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

const ErrorComponent = (props: MsalAuthenticationResult) => <div>{props.error?.errorMessage}</div>;

root.render(
    <React.StrictMode>
        <Provider store={configureAppStore({})}>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginPopupRequest}
                    errorComponent={ErrorComponent}
                >
                    <App />
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
