export enum EmployeeType {
    Manager,
    DirectReport,
}

export enum ResponseState {
    Pending,
    Success,
    Error,
}

export enum FormType {
    Add,
    Update,
}

export enum PrivilegeType {
    Admin,
    Employee,
    Guest,
}

export enum FormActionType {
    Add = 'Add',
    Edit = 'Edit',
    Delete = 'Delete',
}
