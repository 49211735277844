import { Grid } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import { LoaderProgress } from '../../Extensions/LoaderProgress';
import { ResponseState } from '../../Extensions/Types';
import { useAppSelector } from '../../hooks/hooks';
import {
    selectCurrentEmployeeDirectReports,
    selectCurrentEmployeeDirectReportsState,
} from '../../redux/selectors/EmployeeSelectors';
import UserCard from './UserCard';

export default function DirectReportsGrid(): ReactElement {
    const activeEmployeeDirectReports = useAppSelector(selectCurrentEmployeeDirectReports);
    const directReportsState = useAppSelector(selectCurrentEmployeeDirectReportsState);

    return (
        <div
            style={{
                height: '100%',
                width: '60vw',
                margin: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
            }}
        >
            {directReportsState === ResponseState.Success ? (
                <Grid container spacing={5} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    {activeEmployeeDirectReports?.map((dr) => {
                        return (
                            <Grid key={dr.graphUser.id} item xs={12} sm={12} md={6} lg={4} xl={3}>
                                <UserCard key={dr.graphUser.id} userId={dr.graphUser.id} mgAuto={true} mgBottom={20} />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <div style={{ width: 100, height: 100, margin: 'auto' }}>
                    <LoaderProgress />
                </div>
            )}
        </div>
    );
}
