import { AlertData, AlertSeverity } from './Common';
import { FormActionType } from './Types';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function getBase64(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
    });

    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = function () {
    //     return reader.result;
    // };
    // reader.onerror = function (error) {
    //     console.log('Error: ', error);
    //     return 'error';
    // };
    // return reader.result;
}

export const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export const getAlertMessageForForm = (
    formType: FormActionType,
    message: string,
    alertSeverity: AlertSeverity,
): AlertData => {
    const msg =
        formType === FormActionType.Add
            ? alertSeverity === AlertSeverity.success
                ? 'Uspesno ste dodali ' + message
                : alertSeverity === AlertSeverity.error
                ? 'Neuspesno dodavanje ' + message
                : ''
            : formType === FormActionType.Edit
            ? alertSeverity === AlertSeverity.success
                ? 'Uspesno ste promenili ' + message
                : alertSeverity === AlertSeverity.error
                ? 'Neuspesno menjanje ' + message
                : ''
            : formType === FormActionType.Delete
            ? alertSeverity === AlertSeverity.success
                ? 'Uspesno ste izbrisali ' + message
                : alertSeverity === AlertSeverity.error
                ? 'Neuspesno brisanje ' + message
                : ''
            : '';
    const id = getRandomString(12);

    return { id: id, title: msg, type: alertSeverity };
};

export const getRandomString = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const getGUID = (): string => {
    let d = new Date().getTime();
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return guid;
};
