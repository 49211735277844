import { PrivilegeType } from '../../Extensions/Types';
import { RootState } from '../reducers';

export function selectUserPrivilege(state: RootState): PrivilegeType {
    return state.privileges.userPrivilege;
}

export function checkIsAdmin(state: RootState): boolean {
    return state.privileges.userPrivilege === PrivilegeType.Admin;
}

export function checkIsEmployee(state: RootState): boolean {
    return state.privileges.userPrivilege === PrivilegeType.Employee;
}

export function checkIsGuest(state: RootState): boolean {
    return state.privileges.userPrivilege === PrivilegeType.Guest;
}
