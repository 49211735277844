/* eslint-disable @typescript-eslint/no-unused-vars */
import { CacheItem, CacheSchema, CacheService, CacheStore } from '@microsoft/mgt';

const cacheSchema: CacheSchema = {
    name: 'users',
    stores: {
        users: '',
        usersQuery: '',
    },
    version: 2,
};

const cacheSchema2: CacheSchema = {
    name: 'photos',
    stores: {
        users: '',
    },
    version: 2,
};

interface CacheUser extends CacheItem {
    user?: string;
}

// retrieves invalidation time from cache config
const getUserInvalidationTime = (): number =>
    CacheService.config.users.invalidationPeriod || CacheService.config.defaultInvalidationPeriod;

// checks for if cache is enabled
const usersCacheEnabled = (): boolean => CacheService.config.users.isEnabled && CacheService.config.isEnabled;

// declare the desired cache store

export const removeUsersFromCache = (): void => {
    // check if the cache is enabled
    let cache: CacheStore<CacheUser>;
    if (usersCacheEnabled()) {
        cache = CacheService.getCache<CacheUser>(cacheSchema, 'users');
        cache.clearStore();
    }
};

export const removePhotosFromCache = (): void => {
    let cache: CacheStore<CacheUser>;
    if (usersCacheEnabled()) {
        cache = CacheService.getCache<CacheUser>(cacheSchema2, 'users');
        cache.clearStore();
    }
};

// const successCallback = function () {
//     console.log('object has been removed from cache');
// };

// const failureCallback = function () {
//     console.log('error - ');
// };

// export const removeObjectFromCache = (id: string) => {
//     caches.open('IndexedDB/mgt-users/users').then((cache) => {
//         cache.delete(id).then(
//             () => {
//                 successCallback();
//             },
//             () => {
//                 failureCallback;
//             },
//         );
//     });
// };
