import { createSlice } from '@reduxjs/toolkit';
import { AlertData, AlertSeverity } from '../../Extensions/Common';

export interface AlertState {
    alerts: AlertData[];
}

const initialState: AlertState = {
    alerts: [
        {
            id: '0',
            title: 'Mozda se promene nece prikazati odmah. Sacekajte nekoliko minuta pa osvezite stranicu.',
            type: AlertSeverity.info,
        },
    ],
};

export const alertSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        addAlert(state, action) {
            state.alerts = [action.payload, ...(state.alerts ?? [])];
        },
        deleteAlert(state, action) {
            const alertToDelete = action.payload;
            const alerts = state.alerts ?? [];
            const alertIndex = alerts.findIndex((a) => {
                return a.title === alertToDelete.title && a.type === alertToDelete.type;
            });
            state.alerts = [...alerts.slice(0, alertIndex), ...alerts.slice(alertIndex + 1)];
        },
    },
});

export const { addAlert, deleteAlert } = alertSlice.actions;

export default alertSlice.reducer;
