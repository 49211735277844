import { Grid, TextField } from '@mui/material';
import React, { ChangeEvent, ReactElement } from 'react';

type FormTextFieldProps = {
    errorCondition?: boolean;
    entityName: string;
    required?: boolean;
    label?: string;
    defaultValue?: string;
    onChange(event: ChangeEvent<HTMLInputElement>): void;
};

export default function FormTextField(props: FormTextFieldProps): ReactElement {
    return (
        <Grid item xs={12} md={6}>
            <TextField
                error={props.errorCondition ?? false}
                name={props.entityName}
                required={props.required ?? false}
                className="col-l"
                id="filled-basic"
                label={props.label ?? props.entityName}
                value={props.defaultValue}
                variant="standard"
                onChange={props.onChange}
            />
        </Grid>
    );
}
