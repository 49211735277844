/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GraphUserDto, GraphUserManagerDto } from '../../api/msGraphApi';
import { GraphClient } from '../../api/MSGraphExtensions';
import { ResponseState } from '../../Extensions/Types';

export const getCurrentEmployeeData = createAsyncThunk<GraphUserDto, { userId: string }>(
    'currentEmployee/getCurrentEmployeeData',
    async (data) => {
        const { userId } = data;

        return GraphClient.getUserById(userId);
    },
    {
        condition: (arg: { userId: string }, thunkAPI: { getState: any }) => {
            const { userId } = arg;
            return userId !== null && userId !== undefined;
        },
    },
);

export const getCurrentEmployeePhoto = createAsyncThunk<Blob | MediaSource | undefined, { userId: string }>(
    'currentEmployee/getCurrentEmployeePhoto',
    async (data) => {
        const { userId } = data;

        return GraphClient.getUserPhoto(userId ?? 'error');
    },
    {
        condition: (arg: { userId: string }, thunkAPI: { getState: any }) => {
            const { userId } = arg;
            return userId !== null && userId !== undefined;
        },
    },
);

export const getCurrentEmployeeDirectReports = createAsyncThunk<GraphUserDto[], { userId: string }>(
    'currentEmployee/getCurrentEmployeeDirectReports',
    async (data) => {
        const { userId } = data;

        return GraphClient.getUsersDirectReports(userId);
    },
    {
        condition: (arg: { userId: string }, thunkAPI: { getState: any }) => {
            const { userId } = arg;
            return userId !== null && userId !== undefined;
        },
    },
);

export const getCurrentEmployeeDirectReportsPhoto = createAsyncThunk<
    { photo: Blob | MediaSource | undefined; userId: string },
    { userId: string }
>(
    'currentEmployee/getCurrentEmployeeDirectReportsPhoto',
    async (data) => {
        const { userId } = data;

        const photo = await GraphClient.getUserPhoto(userId ?? 'error');

        const userPayload = {
            photo: photo,
            userId: userId,
        };

        return userPayload;
    },
    {
        condition: (arg: { userId: string }, thunkAPI: { getState: any }) => {
            const { userId } = arg;
            return userId !== null && userId !== undefined;
        },
    },
);

export const getCurrentEmployeeManager = createAsyncThunk<GraphUserDto, { userId: string }>(
    'currentEmployee/getCurrentEmployeeManager',
    async (data) => {
        const { userId } = data;
        return GraphClient.getUsersManager(userId);
    },
    {
        condition: (arg: { userId: string }, thunkAPI: { getState: any }) => {
            const { userId } = arg;
            return userId !== null && userId !== undefined;
        },
    },
);

export const getCurrentEmployeeManagerChain = createAsyncThunk<GraphUserManagerDto, { userId: string }>(
    'currentEmployee/getCurrentEmployeeManagerChain',
    async (data) => {
        const { userId } = data;

        return GraphClient.getUsersManagerChain(userId);
    },
    {
        condition: (arg: { userId: string }, thunkAPI: { getState: any }) => {
            const { userId } = arg;
            return userId !== null && userId !== undefined;
        },
    },
);

export const getCurrentEmployeeManagersPhoto = createAsyncThunk<
    { photo: Blob | MediaSource | undefined; userId: string },
    { userId: string }
>(
    'currentEmployee/getCurrentEmployeeManagersPhoto',
    async (data) => {
        const { userId } = data;

        const photo = await GraphClient.getUserPhoto(userId ?? 'error');

        const userPayload = {
            photo: photo,
            userId: userId,
        };

        return userPayload;
    },
    {
        condition: (arg: { userId: string }, thunkAPI: { getState: any }) => {
            const { userId } = arg;
            return userId !== null && userId !== undefined;
        },
    },
);
