/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export interface ICompleteUserInfoDto {
    id: string | undefined;
    displayName: string | undefined;
    givenName: string | undefined;
    surname: string | undefined;
    mailNickname: string | undefined;
    userPrincipalName: string | undefined;
    passwordProfile: {
        forceChangePasswordNextSignIn: boolean;
        password: string;
    };
    businessPhones: string[] | undefined;
    city: string | undefined;
    companyName: string | undefined;
    country: string | undefined;
    department: string | undefined;
    employeeHireDate: string | undefined;
    jobTitle: string | undefined;
    mail: string | undefined;
    mobilePhone: string | undefined;
    officeLocation: string | undefined;
    streetAddress: string | undefined;
    birthday: string | undefined;
    onPremisesExtensionAttributes: ExtensionAttributes | undefined;
}

export class CompleteUserInfoDto implements ICompleteUserInfoDto {
    id!: string | undefined;
    displayName!: string | undefined;
    givenName!: string | undefined;
    surname!: string | undefined;
    mailNickname!: string | undefined;
    userPrincipalName!: string | undefined;
    passwordProfile!: {
        forceChangePasswordNextSignIn: boolean;
        password: string;
    };
    businessPhones!: string[] | undefined;
    city!: string | undefined;
    companyName!: string | undefined;
    country!: string | undefined;
    department!: string | undefined;
    employeeHireDate!: string | undefined;
    jobTitle!: string | undefined;
    mail!: string | undefined;
    mobilePhone!: string | undefined;
    officeLocation!: string | undefined;
    streetAddress!: string | undefined;
    birthday!: string | undefined;
    onPremisesExtensionAttributes!: ExtensionAttributes | undefined;

    constructor(data?: ICompleteUserInfoDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.id = _data['id'];
            this.displayName = _data['displayName'];
            this.givenName = _data['givenName'];
            this.surname = _data['surname'];
            this.mailNickname = _data['mailNickname'];
            this.userPrincipalName = _data['userPrincipalName'];
            this.passwordProfile = _data['passwordProfile'];
            this.businessPhones = _data['businessPhones'];
            this.city = _data['city'];
            this.companyName = _data['companyName'];
            this.country = _data['country'];
            this.department = _data['department'];
            this.employeeHireDate = _data['employeeHireDate'];
            this.jobTitle = _data['jobTitle'];
            this.mail = _data['mail'];
            this.mobilePhone = _data['mobilePhone'];
            this.officeLocation = _data['officeLocation'];
            this.streetAddress = _data['streetAddress'];
            this.birthday = _data['birthday'];
            this.onPremisesExtensionAttributes = _data['onPremisesExtensionAttributes'];
        }
    }

    static fromJS(data: any): CompleteUserInfoDto {
        data = typeof data === 'object' ? data : {};
        const result = new CompleteUserInfoDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['id'] = this.id;
        data['displayName'] = this.displayName;
        data['givenName'] = this.givenName;
        data['surname'] = this.surname;
        data['mailNickname'] = this.mailNickname;
        data['userPrincipalName'] = this.userPrincipalName;
        data['passwordProfile'] = this.passwordProfile;
        data['businessPhones'] = this.businessPhones;
        data['city'] = this.city;
        data['companyName'] = this.companyName;
        data['country'] = this.country;
        data['department'] = this.department;
        data['employeeHireDate'] = this.employeeHireDate;
        data['jobTitle'] = this.jobTitle;
        data['mail'] = this.mail;
        data['mobilePhone'] = this.mobilePhone;
        data['officeLocation'] = this.officeLocation;
        data['streetAddress'] = this.streetAddress;
        data['birthday'] = this.birthday;
        data['onPremisesExtensionAttributes'] = this.onPremisesExtensionAttributes;

        return data;
    }
}

export interface IUserOnCreationDto {
    accountEnabled: boolean | undefined;
    displayName: string | undefined;
    givenName: string | undefined;
    surname: string | undefined;
    mailNickname: string | undefined;
    userPrincipalName: string | undefined;
    passwordProfile: {
        forceChangePasswordNextSignIn: boolean;
        password: string;
    };
    businessPhones: string[] | undefined;
    city: string | undefined;
    companyName: string | undefined;
    country: string | undefined;
    department: string | undefined;
    employeeHireDate: string | undefined;
    jobTitle: string | undefined;
    mail: string | undefined;
    mobilePhone: string | undefined;
    officeLocation: string | undefined;
    streetAddress: string | undefined;
    onPremisesExtensionAttributes: ExtensionAttributes | undefined;
}

export class UserOnCreationDto implements IUserOnCreationDto {
    accountEnabled!: boolean | undefined;
    displayName!: string | undefined;
    givenName!: string | undefined;
    surname!: string | undefined;
    mailNickname!: string | undefined;
    userPrincipalName!: string | undefined;
    passwordProfile!: {
        forceChangePasswordNextSignIn: boolean;
        password: string;
    };
    businessPhones!: string[] | undefined;
    city!: string | undefined;
    companyName!: string | undefined;
    country!: string | undefined;
    department!: string | undefined;
    employeeHireDate!: string | undefined;
    jobTitle!: string | undefined;
    mail!: string | undefined;
    mobilePhone!: string | undefined;
    officeLocation!: string | undefined;
    streetAddress!: string | undefined;
    onPremisesExtensionAttributes!: ExtensionAttributes | undefined;

    constructor(data?: IUserOnCreationDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.accountEnabled = _data['accountEnabled'];
            this.displayName = _data['displayName'];
            this.givenName = _data['givenName'];
            this.surname = _data['surname'];
            this.mailNickname = _data['mailNickname'];
            this.userPrincipalName = _data['userPrincipalName'];
            this.passwordProfile = _data['passwordProfile'];
            this.businessPhones = _data['businessPhones'];
            this.city = _data['city'];
            this.companyName = _data['companyName'];
            this.country = _data['country'];
            this.department = _data['department'];
            this.employeeHireDate = _data['employeeHireDate'];
            this.jobTitle = _data['jobTitle'];
            this.mail = _data['mail'];
            this.mobilePhone = _data['mobilePhone'];
            this.officeLocation = _data['officeLocation'];
            this.streetAddress = _data['streetAddress'];
            this.onPremisesExtensionAttributes = _data['onPremisesExtensionAttributes'];
        }
    }

    static fromJS(data: any): UserOnCreationDto {
        data = typeof data === 'object' ? data : {};
        const result = new UserOnCreationDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['accountEnabled'] = this.accountEnabled;
        data['displayName'] = this.displayName;
        data['givenName'] = this.givenName;
        data['surname'] = this.surname;
        data['mailNickname'] = this.mailNickname;
        data['userPrincipalName'] = this.userPrincipalName;
        data['passwordProfile'] = this.passwordProfile;
        data['businessPhones'] = this.businessPhones;
        data['city'] = this.city;
        data['companyName'] = this.companyName;
        data['country'] = this.country;
        data['department'] = this.department;
        data['employeeHireDate'] = this.employeeHireDate;
        data['jobTitle'] = this.jobTitle;
        data['mail'] = this.mail;
        data['mobilePhone'] = this.mobilePhone;
        data['officeLocation'] = this.officeLocation;
        data['streetAddress'] = this.streetAddress;
        data['onPremisesExtensionAttributes'] = this.onPremisesExtensionAttributes;

        return data;
    }
}

type ExtensionAttributes = {
    extensionAttribute1: string | undefined;
    extensionAttribute2: string | undefined;
    extensionAttribute3: string | undefined;
};
