import * as React from 'react';
import { ReactElement } from 'react';
import { LoaderProgress } from '../../Extensions/LoaderProgress';
import { ResponseState } from '../../Extensions/Types';
import { useAppSelector } from '../../hooks/hooks';
import {
    selectCurrentEmployeeManagerChain,
    selectCurrentEmployeeManagerChainState,
} from '../../redux/selectors/EmployeeSelectors';
import UserCard from './UserCard';

export default function ManagersGrid(): ReactElement {
    const activeEmployeeManagerChain = useAppSelector(selectCurrentEmployeeManagerChain);
    const managerChainState = useAppSelector(selectCurrentEmployeeManagerChainState);

    let currEmp = activeEmployeeManagerChain;

    const grids = [];

    while (currEmp?.manager) {
        grids.push(<UserCard userId={currEmp.manager.id} mgAuto={false} mgBottom={20} />);
        currEmp = currEmp?.manager;
    }

    return (
        <div
            style={{
                height: 453,
                width: '100%',
                display: 'flex',
                flexDirection: 'column-reverse',
                overflowY: 'auto',
                overflowX: 'hidden',
            }}
        >
            {managerChainState === ResponseState.Success ? (
                grids
            ) : (
                <div style={{ width: 100, height: 100, margin: 'auto' }}>
                    <LoaderProgress />
                </div>
            )}
        </div>
    );
}
