import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import React, { ReactElement } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { PrivilegeType } from '../Extensions/Types';
import { RootState } from '../redux/reducers';
import { Alert, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../hooks/hooks';
import { deleteAlert } from '../redux/reducers/alertReducer';
import { AlertSeverity } from '../Extensions/Common';

export default function AdminLayout(): ReactElement {
    const alerts = useSelector((state: RootState) => state.alerts.alerts);

    const dispatch = useAppDispatch();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar layoutType={PrivilegeType.Admin} />
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Outlet />
            </Box>
            {alerts.length > 1 ? (
                <Stack sx={{ width: '60vw', position: 'fixed', bottom: 15, left: '20vw', zIndex: 999999 }} spacing={2}>
                    {alerts.map((a) => {
                        return a.type === AlertSeverity.info ? (
                            <Alert key={a.title} severity={a.type}>
                                {a.title}
                            </Alert>
                        ) : (
                            <Alert
                                key={a.title}
                                severity={a.type}
                                onClose={() => {
                                    dispatch(deleteAlert(a));
                                }}
                            >
                                {a.title}
                            </Alert>
                        );
                    })}
                </Stack>
            ) : (
                <></>
            )}
        </Box>
    );
}
