import { AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './AuthConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as AuthenticationResult).account) {
        const account = (event.payload as AuthenticationResult).account;
        msalInstance.setActiveAccount(account);
    }
});
