import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import EmployeeDetails from '../Features/Employees/EmployeeDetails';
import EmployeesList from '../Features/Employees/EmployeesList';
import EmployeeLayout from '../Layouts/EmployeeLayout';
import AdminLayout from '../Layouts/AdminLayout';
import AddEmployeeForm from '../Forms/EmployeeForm/AddEmployeeForm';
import { useSelector } from 'react-redux';
import { checkIsAdmin } from '../redux/selectors/PrivilegesSelector';

export default function AppRouter(): ReactElement {
    const isAdmin = useSelector(checkIsAdmin);

    return (
        <div>
            <Routes>
                <Route path="/" element={isAdmin ? <AdminLayout /> : <EmployeeLayout />}>
                    <Route index element={<EmployeeDetails />} />
                    <Route path="employees" element={<EmployeesList />} />
                    <Route path="employees/unlinked" element={<EmployeesList />} />
                    <Route path="employees/add_form" element={<AddEmployeeForm />} />
                </Route>

                <Route path="employee/:employeeId" element={isAdmin ? <AdminLayout /> : <EmployeeLayout />}>
                    <Route index element={<EmployeeDetails />} />
                    <Route path="update_form" element={<AddEmployeeForm />} />
                </Route>
            </Routes>
        </div>
    );
}
