import React, { useEffect } from 'react';

import { Box } from '@mui/system';

import { ReactElement } from 'react';
import { useAppDispatch } from '../../hooks/hooks';
import {
    getCurrentEmployeeData,
    getCurrentEmployeeDirectReports,
    getCurrentEmployeeManagerChain,
} from '../../redux/actions/currentEmployeeActions';

import './EmployeeDetails.css';
import '../../Extensions/Common.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import MyInfo from './MyInfo';
import ManagersGrid from './ManagersGrid';
import DirectReportsGrid from './DirectReportsGrid';
import { checkForAdminPrivileges } from '../../Extensions/AdminChecker';

export default function EmployeeDetails(): ReactElement {
    const employee = useSelector((root: RootState) => root.loggedEmployee.basicEmployee);

    const dispatch = useAppDispatch();

    const { employeeId } = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        if (employee && employee.id) {
            dispatch(checkForAdminPrivileges(employee.id));
        }
    }, [employee]);

    useEffect(() => {
        if (employeeId) {
            dispatch(getCurrentEmployeeData({ userId: employeeId ?? 'error' }))
                .unwrap()
                .then((res) => {
                    dispatch(getCurrentEmployeeDirectReports({ userId: res.id ?? 'error' }));
                    dispatch(getCurrentEmployeeManagerChain({ userId: res.id ?? 'error' }));
                });
        } else {
            if (employee) {
                navigate('employee/' + employee.id);
            }
        }
    }, [employee, employeeId, location]);

    return (
        <div>
            <Box sx={{ p: 5 }}>
                <div className="scrollbar-styled" style={{ width: '60vw', margin: 'auto' }}>
                    <div
                        style={{
                            width: 240,
                            float: 'left',
                            height: 453,
                            marginBottom: 20,
                        }}
                    >
                        <ManagersGrid />
                    </div>
                    <div style={{ width: 'calc(100% - 250px)', minWidth: 300, float: 'right', position: 'relative' }}>
                        <div
                            style={{
                                position: 'absolute',
                                backgroundColor: 'white',
                                width: 253,
                                height: 150,
                                left: -250,
                                bottom: 0,
                                boxShadow: '0px 5px 5px #aaa',
                                clipPath: 'inset(0px 1px -10px -5px)',
                                borderRadius: '7px 0px 0px 7px',
                            }}
                        ></div>
                        <MyInfo />
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    <div
                        style={{
                            margin: 'auto',
                            marginTop: 20,
                        }}
                    >
                        <DirectReportsGrid />
                    </div>
                </div>
            </Box>
        </div>
    );
}
