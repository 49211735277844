import { createReducer } from '@reduxjs/toolkit';
import { EmployeeInfoDto } from '../../api/clientApi';
import { ClientUserDto } from '../../api/models/ClientUserDto';
import { GraphUserDto, GraphUserManagerDto } from '../../api/msGraphApi';
import { ResponseState } from '../../Extensions/Types';
import {
    getCurrentEmployeeData,
    getCurrentEmployeeDirectReports,
    getCurrentEmployeeDirectReportsPhoto,
    getCurrentEmployeeManager,
    getCurrentEmployeeManagerChain,
    getCurrentEmployeeManagersPhoto,
    getCurrentEmployeePhoto,
} from '../actions/currentEmployeeActions';

export interface CurrentEmployeeState {
    currentEmployee: ClientUserDto | null;
    currentEmployeeState: ResponseState | null;
    currEmployee: EmployeeInfoDto | null;

    directReports: ClientUserDto[] | null;
    directReportsState: ResponseState | null;
    manager: ClientUserDto | null;
    managerChain: GraphUserManagerDto | null;
    managerChainState: ResponseState | null;

    error: string | null;
}

export const currentEmployeeReducer = createReducer<CurrentEmployeeState>(
    {
        currentEmployee: null,
        currentEmployeeState: null,
        currEmployee: null,
        directReports: null,
        directReportsState: null,
        manager: null,
        managerChain: null,
        managerChainState: null,
        error: null,
    },
    (builder) => {
        builder
            .addCase(getCurrentEmployeeData.fulfilled, (state: CurrentEmployeeState, action: any) => {
                const info: GraphUserDto = action.payload;

                const newUser = new ClientUserDto();
                const newUserData = { graphUser: info, photo: undefined };

                newUser.init(newUserData);

                state.currentEmployee = newUser;
                state.currentEmployeeState = ResponseState.Success;
            })
            .addCase(getCurrentEmployeeData.pending, (state: CurrentEmployeeState) => {
                state.currentEmployeeState = ResponseState.Pending;
                state.directReportsState = ResponseState.Pending;
                state.managerChainState = ResponseState.Pending;
            })
            .addCase(getCurrentEmployeePhoto.fulfilled, (state: CurrentEmployeeState, action: any) => {
                if (state.currentEmployee) {
                    state.currentEmployee.photo = action.payload;
                }
            })
            .addCase(getCurrentEmployeeDirectReports.fulfilled, (state: CurrentEmployeeState, action: any) => {
                const users: GraphUserDto[] = action.payload;
                const newDirectReports: ClientUserDto[] = [];

                for (let i = 0; i < users.length; i++) {
                    const newDirectReport = new ClientUserDto();
                    const newUserData = {
                        graphUser: users[i],
                        photo: undefined,
                    };
                    newDirectReport.init(newUserData);
                    if (newDirectReport != null) {
                        newDirectReports.push(newDirectReport);
                    }
                }

                state.directReports = newDirectReports;
                state.directReportsState = ResponseState.Success;
            })
            .addCase(getCurrentEmployeeDirectReports.pending, (state: CurrentEmployeeState) => {
                state.directReportsState = ResponseState.Pending;
            })
            .addCase(getCurrentEmployeeDirectReportsPhoto.fulfilled, (state: CurrentEmployeeState, action: any) => {
                const { photo, userId } = action.payload;

                const dirRepIndex = state.directReports?.findIndex((dr) => {
                    return dr.graphUser.id === userId;
                });

                if (dirRepIndex && state.directReports) {
                    state.directReports[dirRepIndex].photo = photo;
                }
            })
            .addCase(getCurrentEmployeeManager.fulfilled, (state: CurrentEmployeeState, action: any) => {
                const info: GraphUserDto = action.payload;

                const photo = state.manager ? state.manager.photo : undefined;
                const newUser = new ClientUserDto();
                const newUserData = { graphUser: info, photo: photo };

                newUser.init(newUserData);

                state.manager = newUser;
            })
            .addCase(getCurrentEmployeeManagerChain.fulfilled, (state: CurrentEmployeeState, action: any) => {
                const info: GraphUserManagerDto = action.payload;

                state.managerChain = info;
                state.managerChainState = ResponseState.Success;
            })
            .addCase(getCurrentEmployeeManagerChain.pending, (state: CurrentEmployeeState) => {
                state.managerChainState = ResponseState.Pending;
            })
            .addCase(getCurrentEmployeeManagersPhoto.fulfilled, (state: CurrentEmployeeState, action: any) => {
                const { photo, userId } = action.payload;

                let mg = state.managerChain?.manager;

                while (mg) {
                    if (mg.id === userId) {
                        mg.photo = photo;
                        break;
                    }
                    mg = mg.manager;
                }
            });
    },
);
