import { Paper, Typography, Stepper, Step, StepLabel, Button } from '@mui/material';
import React, { ReactElement } from 'react';
import '../Forms.css';

type FormTemplateProps = {
    title: string;
    submit(): void;
    shouldDisable: boolean;
    formContent: ReactElement;
    steps: string[];
    activeStep: number;
    handleNext(): void;
    handleBack(): void;
    wideWindow: boolean;
    disableBack?: boolean;
};

export default function FormTemplate(props: FormTemplateProps): ReactElement {
    return (
        <>
            <main className="layout">
                <Paper className={'paper'}>
                    <Typography component="h4" variant="h4" align="center">
                        {props.title}
                    </Typography>
                    <Stepper activeStep={props.activeStep} className="stepper">
                        {props.steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <>
                        {props.formContent}
                        <div className="buttons">
                            {props.activeStep !== 0 && !props.disableBack && (
                                <Button onClick={props.handleBack} className="button">
                                    Nazad
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                className="button"
                                onClick={() => {
                                    props.activeStep === props.steps.length - 1 ? props.submit() : props.handleNext();
                                }}
                                disabled={props.shouldDisable}
                            >
                                {props.activeStep === props.steps.length - 1 ? 'Sacuvaj' : 'Dalje'}
                            </Button>
                        </div>
                    </>
                </Paper>
            </main>
        </>
    );
}
