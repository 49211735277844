/* eslint-disable @typescript-eslint/no-unused-vars */
import { createReducer } from '@reduxjs/toolkit';
import { GraphUserDto } from '../../api/msGraphApi';
import { getEmployeeData } from '../actions/employeeActions';

export enum LoginStatus {
    InProgress = 'InProgress',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
}

export interface LoggedEmployeeState {
    basicEmployee: GraphUserDto | null;

    logginStatus: LoginStatus | null;

    error: string | null;
}

export const loggedEmployeeReducer = createReducer<LoggedEmployeeState>(
    {
        basicEmployee: null,
        error: null,
        logginStatus: null,
    },
    (builder) => {
        builder.addCase(getEmployeeData.fulfilled, (state: LoggedEmployeeState, action: any) => {
            const info: GraphUserDto = action.payload;

            state.basicEmployee = info;
        });
    },
);
