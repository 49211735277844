/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GraphUserDto } from '../../api/msGraphApi';
import { GraphClient } from '../../api/MSGraphExtensions';
import { RootState } from '../reducers';
import { LoggedEmployeeState } from '../reducers/loggedEmployeeReducer';

export const getEmployeeData = createAsyncThunk<GraphUserDto, void>(
    'employee/getEmployeeData',
    async () => {
        return GraphClient.getMe();
    },
    {
        condition: (arg: void, thunkAPI: { getState: any }) => {
            const employeeState: LoggedEmployeeState = (thunkAPI.getState() as RootState).loggedEmployee;
            const employeeInfo: GraphUserDto | null = employeeState.basicEmployee;

            return employeeInfo === null;
        },
    },
);
