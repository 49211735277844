import * as React from 'react';
import { MgtPersonCard } from '@microsoft/mgt-react';
import { LocalizationHelper } from '@microsoft/mgt';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PersonCardTemplate from './PersonCardTemplate';
import { getPhotoUrl, MenuOptions, NameDataPair } from '../../Extensions/Common';
import EditIcon from '@mui/icons-material/Edit';
import IconMenu from '../../Common/IconMenu';
import { useSelector } from 'react-redux';
import { checkIsAdmin } from '../../redux/selectors/PrivilegesSelector';
import { GraphClient } from '../../api/MSGraphExtensions';
import { Companies, CompanyNames } from '../../Extensions/JPGroupRoleHelper';
import { Modal } from '@mui/material';
import AlertWindow from '../../Common/AlertWindow';
import NonAdminPersonCardTemplate from './NonAdminPersonCardTemplate';
import { CompleteUserInfoDto } from '../../Extensions/UserOnCreation';

LocalizationHelper.strings = {
    noResultsFound: 'nema podataka',
    _components: {
        'person-card': {
            sendEmailLinkSubtitle: 'Posalji email',
            startChatLinkSubtitle: 'Zapocni chat',
            showMoreSectionButton: 'Vise',
        },
        'person-card-contact': {
            contactSectionTitle: 'Kontakt',
        },
        'person-card-organization': {
            reportsToSectionTitle: 'Nadredjeni',
            directReportsSectionTitle: 'Podredjeni',
            organizationSectionTitle: 'Organizacija',
            youWorkWithSubSectionTitle: 'Radis sa',
            userWorksWithSubSectionTitle: 'radi sa',
        },
        'person-card-messages': {
            emailsSectionTitle: 'e-mails',
        },
        'person-card-files': {
            filesSectionTitle: 'Fajlovi',
            sharedTextSubtitle: 'Podeljeno',
        },
        'person-card-profile': {
            SkillsAndExperienceSectionTitle: 'Vestine i iskustvo',
            AboutCompactSectionTitle: 'O radniku',
            SkillsSubSectionTitle: 'Vestine',
            LanguagesSubSectionTitle: 'Jezici',
            WorkExperienceSubSectionTitle: 'Radno iskustvo',
            EducationSubSectionTitle: 'Edukacija',
            professionalInterestsSubSectionTitle: 'Profesionalna interesovanja',
            personalInterestsSubSectionTitle: 'Licna interesovanja',
            birthdaySubSectionTitle: 'Rodjendan',
            currentYearSubtitle: 'Trenutno',
        },
    },
};

export default function MyInfo(): ReactElement {
    const [alertData, setAlertData] = useState<NameDataPair[]>([]);
    const [passwordResetAlert, setPasswordResetAlert] = useState<boolean>(false);
    const [expand, setExpand] = useState(false);
    const [person, setPerson] = useState<CompleteUserInfoDto | undefined>(undefined);
    const [personImage, setPersonImage] = useState<string | undefined>(undefined);
    const isAdmin = useSelector(checkIsAdmin);

    const { employeeId } = useParams();

    const navigate = useNavigate();

    MgtPersonCard.config.sections.organization = false;

    useEffect(() => {
        if (!expand) {
            setTimeout(() => {
                setExpand(true);
            }, 1000);
        }
    }, []);

    useEffect(() => {
        if (employeeId) {
            GraphClient.getUserCompleteInfo(employeeId).then((res) => setPerson(res));
            GraphClient.getUserPhoto(employeeId).then((res) => {
                if (res) {
                    setPersonImage(getPhotoUrl(res));
                }
            });
        }
    }, [employeeId]);

    const navigateToUpdateForm = () => {
        navigate('update_form');
    };

    const openNewEmployeeInfoAlert = (data: NameDataPair[]) => {
        setAlertData(data);
        setPasswordResetAlert(true);
    };

    const closeNewEmployeeInfoAlert = () => {
        setPasswordResetAlert(false);
    };

    const resetPassword = () => {
        let newPass = CompanyNames.find((cn) => cn.name == Companies[Companies.GranitDOO])?.data;
        if (employeeId && newPass) {
            newPass += new Date().getUTCFullYear() + '!';
            GraphClient.resetUsersPassword(employeeId, newPass).then(() => {
                GraphClient.getUserPasswordProfile(employeeId).then((res) => {
                    const newData: NameDataPair[] = [
                        {
                            name: 'Korisnicko ime: ',
                            data: res.userPrincipalName,
                        },
                        {
                            name: 'Sifra: ',
                            data: res.passwordProfile?.password ?? newPass,
                        },
                    ];
                    openNewEmployeeInfoAlert(newData);
                });
            });
        }
    };

    const menuData: MenuOptions[] = [
        {
            title: 'Promeni detalje o radniku',
            func: navigateToUpdateForm,
            icon: <EditIcon />,
        },
        {
            title: 'Restartuj sifru radnika',
            func: resetPassword,
            icon: <EditIcon />,
        },
    ];

    return (
        <>
            <div className="my-info-container" key={employeeId}>
                <div style={{ position: 'relative' }}>
                    {isAdmin ? (
                        <PersonCardTemplate person={person} personImage={personImage} />
                    ) : (
                        <NonAdminPersonCardTemplate person={person} personImage={personImage} />
                    )}

                    {isAdmin && (
                        <div className="employee-menu-icon">
                            <IconMenu options={menuData} />
                        </div>
                    )}
                </div>
            </div>

            <Modal
                open={passwordResetAlert}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AlertWindow
                    close={closeNewEmployeeInfoAlert}
                    data={alertData}
                    title="Bitne informacije o novom radniku!"
                    disclaimer={`Sacuvajte ove informacije pre zatvaranja prozora!!! Nakon zatvaranja ovog prozora necete imati pristup gore navedenim informacijama!`}
                />
            </Modal>
        </>
    );
}
