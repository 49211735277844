/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EmployeeAccountDto, EmployeeDto, IJobPositionDto, JobPositionDto, JobPositionType } from '../../api/clientApi';
import { GranitTransportVNextClient, GranitArmVNextClient } from '../../api/Extension';
import { AppRoleAssignmentDto, ServicePrincipalDto } from '../../api/msGraphApi';
import { GraphClient } from '../../api/MSGraphExtensions';
import { AlertSeverity } from '../../Extensions/Common';
import { FormActionType, ResponseState } from '../../Extensions/Types';
import {
    JobPositionGroupId,
    AppDisplayName,
    RoleDisplayName,
    getRoleDisplayNameOfAppDisplayNameAndJT,
    getAppOfJobPostionType,
    Departments,
} from '../../Extensions/JPGroupRoleHelper';
import { getBase64, getAlertMessageForForm, b64toBlob } from '../../Extensions/Utills';
import { addAlert } from '../../redux/reducers/alertReducer';
import { ClientEnv, Environment } from '../../environments/environment';

const assAppRoleToEmp = (userId: string, appRoleAssignHRPanel: AppRoleAssignmentDto) => {
    GraphClient.assignAppRole(userId, appRoleAssignHRPanel);
};

export const addProfilePic =
    (userId: string, profImg: File, actionType: FormActionType) =>
    (dispatch: (arg0: { payload: any; type: string }) => void) => {
        const img = profImg;
        if (img) {
            getBase64(img).then((b64Img) => {
                const imgB = b64toBlob(b64Img as string);
                GraphClient.updateUserProfilePhoto(userId, imgB).then(
                    (res) => {
                        if (res === ResponseState.Success) {
                            const alert = getAlertMessageForForm(
                                actionType,
                                'profilnu fotografiju',
                                AlertSeverity.success,
                            );
                            dispatch(addAlert(alert));
                        } else {
                            const alert = getAlertMessageForForm(
                                actionType,
                                'profilnu fotografiju',
                                AlertSeverity.error,
                            );
                            dispatch(addAlert(alert));
                        }
                    },
                    () => {
                        const alert = getAlertMessageForForm(actionType, 'profilnu fotografiju', AlertSeverity.error);
                        dispatch(addAlert(alert));
                    },
                );
            });
        }
    };

export const addMgr = (userId: string, mgrId: string) => {
    if (mgrId != '') {
        GraphClient.assignManager(userId, mgrId);
    }
};

export const addEmpToGroup = (jt: string, userId: string | undefined) => {
    const groupId = JobPositionGroupId.find((jpgid) => {
        return jpgid.name == jt;
    })?.data;
    if (groupId && groupId != '') {
        GraphClient.addMemberToGroup(userId ?? 'error', groupId);
    }
};

export const removeEmpFromGroup = (jt: string, userId: string | undefined) => {
    const groupId = JobPositionGroupId.find((jpgid) => {
        return jpgid.name == jt;
    })?.data;
    if (groupId && groupId != '') {
        GraphClient.removeMemberFromGroup(userId ?? 'error', groupId);
    }
};

const assignAppRoleToEmp = (
    servicePrincipals: ServicePrincipalDto[],
    appDisplayName: AppDisplayName,
    userId: string,
    jt: JobPositionType,
    appRoleDisplayName?: RoleDisplayName,
) => {
    const appRoleAssign: AppRoleAssignmentDto = new AppRoleAssignmentDto();
    const servicePrincipal = servicePrincipals.find((sp) => sp.appDisplayName === appDisplayName);
    if (servicePrincipal) {
        appRoleAssign.principalId = userId;
        appRoleAssign.resourceId = servicePrincipal.id;
        if (servicePrincipal.appRoles && servicePrincipal.appRoles.length > 0) {
            if (appRoleDisplayName) {
                const appRole = servicePrincipal.appRoles.find((r) => r.displayName == appRoleDisplayName);
                if (appRole) {
                    appRoleAssign.appRoleId = appRole?.id;
                    GraphClient.assignAppRole(userId, appRoleAssign);
                }
            } else {
                const appRoleDispName = getRoleDisplayNameOfAppDisplayNameAndJT(appDisplayName, jt);
                const appRole = servicePrincipal.appRoles.find((r) => r.displayName == appRoleDispName);

                if (appRole) {
                    appRoleAssign.appRoleId = appRole?.id;
                    GraphClient.assignAppRole(userId, appRoleAssign);
                }
            }
        }
    }
};

export const assignAppRoleToEmployee = (userId: string, jt: JobPositionType) => {
    const env = ClientEnv;
    GraphClient.getServicePrincipals().then((res) => {
        assignAppRoleToEmp(res, AppDisplayName.GranitHRPanel, userId, jt);
        assignAppRoleToEmp(res, AppDisplayName.GranitAPI, userId, jt);
        assignAppRoleToEmp(res, AppDisplayName.GranitAPI, userId, jt, RoleDisplayName.GranitAPI_Employee);
        if (jt === JobPositionType.ArmatureOperator || jt === JobPositionType.ArmatureWorker) {
            if (env === Environment.Prod || env === Environment.Ppe) {
                assignAppRoleToEmp(res, AppDisplayName.GranitArmClient, userId, jt);
            } else {
                assignAppRoleToEmp(res, AppDisplayName.GranitArmClientInt, userId, jt);
            }
        }
        if (jt === JobPositionType.Driver || jt === JobPositionType.TransportOperator) {
            assignAppRoleToEmp(res, AppDisplayName.TransportClient, userId, jt);
        }
    });
};

const changeEmployeeAppRole = (
    servicePrincipals: ServicePrincipalDto[],
    appDispName: AppDisplayName,
    userId: string,
    oldJt: JobPositionType,
    newJt: JobPositionType,
) => {
    const appRoleAssign: AppRoleAssignmentDto = new AppRoleAssignmentDto();
    const serPrinc = servicePrincipals.find((sp) => sp.appDisplayName === appDispName);
    if (serPrinc) {
        appRoleAssign.principalId = userId;
        appRoleAssign.resourceId = serPrinc.id;
        if (serPrinc.appRoles && serPrinc.appRoles.length > 0) {
            const oldAppRoleDispName = getRoleDisplayNameOfAppDisplayNameAndJT(appDispName, oldJt);
            const newAppRoleDispName = getRoleDisplayNameOfAppDisplayNameAndJT(appDispName, newJt);

            const oldAppRole = serPrinc.appRoles.find((r) => r.displayName == oldAppRoleDispName);

            const newAppRole = serPrinc.appRoles.find((r) => r.displayName == newAppRoleDispName);

            if (newAppRole && newAppRole.id && oldAppRole && oldAppRole.id && newAppRole.id === oldAppRole.id) {
                console.log('SAME ROLE');
            } else if (newAppRole && newAppRole.id) {
                if (oldAppRole && oldAppRole.id) {
                    GraphClient.getUserAppRoles(userId).then((res) => {
                        const oldRoleAssId = res.find((ar) => ar.appRoleId === oldAppRole.id)?.id;

                        if (oldRoleAssId) {
                            GraphClient.removeAppRole(userId, oldRoleAssId).then(
                                () => {
                                    appRoleAssign.appRoleId = newAppRole.id;
                                    assAppRoleToEmp(userId, appRoleAssign);
                                },
                                () => {
                                    appRoleAssign.appRoleId = newAppRole?.id;
                                    assAppRoleToEmp(userId, appRoleAssign);
                                },
                            );
                        } else {
                            appRoleAssign.appRoleId = newAppRole?.id;
                            assAppRoleToEmp(userId, appRoleAssign);
                        }
                    });
                } else {
                    appRoleAssign.appRoleId = newAppRole?.id;
                    assAppRoleToEmp(userId, appRoleAssign);
                }
            } else if (oldAppRole && oldAppRole.id) {
                GraphClient.getUserAppRoles(userId).then((res) => {
                    const oldRoleAssId = res.find((ar) => ar.appRoleId === oldAppRole.id)?.id;

                    if (oldRoleAssId) {
                        GraphClient.removeAppRole(userId, oldRoleAssId);
                    }
                });
            }
        }
    }
};

export const changeEmployeeAppsRoles = (userId: string, oldJt: JobPositionType, newJt: JobPositionType) => {
    GraphClient.getServicePrincipals().then((res) => {
        const env = ClientEnv;
        changeEmployeeAppRole(res, AppDisplayName.GranitHRPanel, userId, oldJt, newJt);
        changeEmployeeAppRole(res, AppDisplayName.GranitAPI, userId, oldJt, newJt);
        if (
            oldJt === JobPositionType.ArmatureOperator ||
            oldJt === JobPositionType.ArmatureWorker ||
            newJt === JobPositionType.ArmatureOperator ||
            newJt === JobPositionType.ArmatureWorker
        ) {
            if (env === Environment.Prod || env === Environment.Ppe) {
                changeEmployeeAppRole(res, AppDisplayName.GranitArmClient, userId, oldJt, newJt);
            } else {
                changeEmployeeAppRole(res, AppDisplayName.GranitArmClientInt, userId, oldJt, newJt);
            }
        }
        if (
            oldJt === JobPositionType.Driver ||
            oldJt === JobPositionType.TransportOperator ||
            newJt === JobPositionType.Driver ||
            newJt === JobPositionType.TransportOperator
        ) {
            changeEmployeeAppRole(res, AppDisplayName.TransportClient, userId, oldJt, newJt);
        }
    });
};

export const addEmployeeOB =
    (employee: EmployeeDto, empAcc: EmployeeAccountDto, jp?: JobPositionType) => (dispatch: (arg0: any) => void) => {
        const dep = getAppOfJobPostionType(jp);
        const GranitVNextClient = dep === Departments.Armature ? GranitArmVNextClient : GranitTransportVNextClient;

        GranitVNextClient.addNewEmployee(employee).then(
            (res) => {
                const alert = getAlertMessageForForm(FormActionType.Add, 'radnika u bazu', AlertSeverity.success);
                dispatch(addAlert(alert));
                if (res.azObjId) {
                    if (jp) {
                        addJobPositionOB(res.azObjId, jp);
                    }
                    empAcc.employeeId = res.id;
                    addNewEmpAccountOB(res.azObjId, empAcc, jp);
                }
            },
            () => {
                const alert = getAlertMessageForForm(FormActionType.Add, 'radnika u bazu', AlertSeverity.error);
                dispatch(addAlert(alert));
            },
        );
    };

export const addJobPositionOB = (empAzId: string, jp: JobPositionType) => {
    const dep = getAppOfJobPostionType(jp);
    const GranitVNextClient = dep === Departments.Armature ? GranitArmVNextClient : GranitTransportVNextClient;
    GranitVNextClient.getEmployeeByAzureObjId(empAzId, false, false, false).then((r) => {
        const ijp: IJobPositionDto = new JobPositionDto();

        ijp.employeeId = r.id;
        ijp.jobPositionType = jp;

        const jpDto = new JobPositionDto(ijp);
        GranitVNextClient.addNewJobPosition(r.id, jpDto);
    });
};

export const deleteJobPositionOB = (empAzId: string, oldJpType: JobPositionType, jp: JobPositionType) => {
    const dep = getAppOfJobPostionType(jp);
    const GranitVNextClient = dep === Departments.Armature ? GranitArmVNextClient : GranitTransportVNextClient;
    GranitVNextClient.getEmployeeByAzureObjId(empAzId, false, true, false).then((r) => {
        const oldJp = r.jobPositions?.find((jp) => jp.jobPositionType === oldJpType);

        if (oldJp) {
            GranitVNextClient.deleteJobPosition(oldJp.id, oldJp);
        }
    });
};

export const addNewEmpAccountOB = (empAzId: string, empAcc: EmployeeAccountDto, jp?: JobPositionType) => {
    const dep = getAppOfJobPostionType(jp);
    const GranitVNextClient = dep === Departments.Armature ? GranitArmVNextClient : GranitTransportVNextClient;
    GranitVNextClient.getEmployeeByAzureObjId(empAzId, false, false, false).then((r) => {
        if (empAcc && r && r.id) {
            GranitVNextClient.addNewEmployeeAccount(r.id, empAcc);
        } else {
            console.log('error emp acc');
        }
    });
};
