import { Configuration, LogLevel, PopupRequest } from '@azure/msal-browser';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: 'bb31e5fc-6a9d-445b-a523-364423aaaf3f', // This is the ONLY mandatory field that you need to supply
        authority: 'https://login.microsoftonline.com/e6a91e9a-4d04-4df7-80ad-a9ed41471d81', // Defaults to "https://login.windows-ppe.net/common"
        redirectUri: window.location.origin, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
    },
};

export const loginPopupRequest: PopupRequest = {
    scopes: ['User.Read', 'User.Read.All'],
    extraScopesToConsent: ['api://granitdoo/Employees.Read.All'],
};

// 'Contacts.Read',
// 'People.Read',
// 'People.Read.All',
// 'Presence.Read.All',
// 'Sites.Read.All',

// NEEDED FOR ADMIN PRIVILAGES
//'User.ReadWrite.All',
//'Application.Read.All',
//'GroupMember.ReadWrite.All',
//'AppRoleAssignment.ReadWrite.All',
//'Directory.AccessAsUser.All',
