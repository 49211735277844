import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ReactElement } from 'react';
import { GraphUserManagerDto } from '../../api/msGraphApi';
import { GraphClient } from '../../api/MSGraphExtensions';
import UserCard from './UserCard';

export default function EmployeesList(): ReactElement {
    const [unlinkedUsers, setUnlinkedUsers] = useState<GraphUserManagerDto[]>([]);

    useEffect(() => {
        GraphClient.getAllUnlinkedUsers().then((res) => {
            setUnlinkedUsers(res);
        });
    }, []);

    return (
        <div style={{ width: '80vw', height: '80vh', margin: 'auto', padding: 20 }}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Typography component="h4" variant="h4" style={{ textAlign: 'center' }}>
                        Radnici van hijerarhije
                    </Typography>
                </Grid>
                {unlinkedUsers
                    .filter((u) => {
                        return !u.manager && u.jobTitle !== 'CEO';
                    })
                    .map((uu) => {
                        return (
                            <Grid key={uu.id} item xs={12} sm={6} md={4} lg={3} style={{ cursor: 'pointer' }}>
                                <UserCard userId={uu.id} mgAuto={false} mgBottom={20} />
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
}
