import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AdbIcon from '@mui/icons-material/Adb';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { ReactElement } from 'react';
import { getEmployeeData } from '../redux/actions/employeeActions';
import { useAppDispatch } from '../hooks/hooks';
import AppRouter from '../Router/AppRouter';
import { Person, ViewType } from '@microsoft/mgt-react';
import AppsDialog from '../Features/Common/AppsDialog';
import { GranitTransportVNextClient } from '../api/Extension';

export default function AppLayout(): ReactElement {
    const mdTheme = createTheme();

    const isAuthenticated = useIsAuthenticated();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { instance, accounts } = useMsal();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        dispatch(getEmployeeData());
        GranitTransportVNextClient.getMyInfo();
    }, [isAuthenticated, instance]);

    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                <ThemeProvider theme={mdTheme}>
                    <AppBar position="fixed">
                        <Container maxWidth={false}>
                            <Toolbar disableGutters>
                                <AdbIcon sx={{ display: { xs: 'flex' }, mr: 1 }} />
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    href="/"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'flex' },
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    HRPanel
                                </Typography>
                                <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}></Box>
                                <AppsDialog></AppsDialog>
                                <Box sx={{ flexGrow: 0 }}>
                                    <Person
                                        userId={instance.getActiveAccount()!.localAccountId}
                                        view={ViewType.image}
                                    />
                                </Box>
                            </Toolbar>
                        </Container>
                    </AppBar>
                    <main style={{ marginTop: 64 }}>
                        <AppRouter />
                    </main>
                </ThemeProvider>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>You are not signed in! Please sign in.</p>
            </UnauthenticatedTemplate>
        </React.Fragment>
    );
}
