import { Outlet } from 'react-router-dom';

import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import { ReactElement } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { PrivilegeType } from '../Extensions/Types';

export default function EmployeeLayout(): ReactElement {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Sidebar layoutType={PrivilegeType.Employee} />
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Outlet />
            </Box>
        </Box>
    );
}
