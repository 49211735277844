/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GraphUserDto } from '../msGraphApi';

export interface IClientUserDto {
    graphUser: GraphUserDto;
    photo: Blob | MediaSource | undefined;
}

export class ClientUserDto implements IClientUserDto {
    graphUser!: GraphUserDto;
    photo!: Blob | MediaSource | undefined;

    constructor(data?: IClientUserDto) {
        if (data) {
            for (const property in data) {
                if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any): void {
        if (_data) {
            this.graphUser = _data['graphUser'];
            this.photo = _data['photo'];
        }
    }

    static fromJS(data: any): GraphUserDto {
        data = typeof data === 'object' ? data : {};
        const result = new GraphUserDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data['graphUser'] = this.graphUser;
        data['photo'] = this.photo;

        return data;
    }
}
