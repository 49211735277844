import { Grid, Stack, TextField } from '@mui/material';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { ChangeEvent, ReactElement } from 'react';
import { ICompleteUserInfoDto } from '../../../Extensions/UserOnCreation';
import FormTextField from '../../Common/FormTextField';

type EmployeeInfoProps = {
    employee: ICompleteUserInfoDto;
    isUpdateForm: boolean;
    handleInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
    handleUcinChange(event: ChangeEvent<HTMLInputElement>): void;
    handlePerNumChange(event: ChangeEvent<HTMLInputElement>): void;
    handleBirthdayChange(date?: string): void;
};

export default function EmployeeInfo(props: EmployeeInfoProps): ReactElement {
    return (
        <>
            <Grid container spacing={5}>
                <FormTextField
                    defaultValue={props.employee?.givenName}
                    required={true}
                    label="Ime"
                    entityName="givenName"
                    errorCondition={props.employee?.givenName ? false : true}
                    onChange={props.handleInputElementChange}
                />
                <FormTextField
                    defaultValue={props.employee?.surname}
                    required={true}
                    label="Prezime"
                    entityName="surname"
                    errorCondition={props.employee?.surname ? false : true}
                    onChange={props.handleInputElementChange}
                />
                <FormTextField
                    defaultValue={props.employee?.mail}
                    label="E-mail"
                    entityName="mail"
                    onChange={props.handleInputElementChange}
                />
                <FormTextField
                    defaultValue={props.employee?.mobilePhone}
                    label="Licni telefon"
                    entityName="mobilePhone"
                    onChange={props.handleInputElementChange}
                />
                <FormTextField
                    defaultValue={props.employee?.streetAddress}
                    label="Licna adresa"
                    entityName="streetAddress"
                    onChange={props.handleInputElementChange}
                />
                <FormTextField
                    defaultValue={props.employee?.city}
                    label="Grad"
                    entityName="city"
                    onChange={props.handleInputElementChange}
                />
                <FormTextField
                    defaultValue={props.employee?.country}
                    label="Drzava"
                    entityName="country"
                    onChange={props.handleInputElementChange}
                />
                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Datum rodjenja"
                                disableMaskedInput
                                value={props.employee.onPremisesExtensionAttributes?.extensionAttribute3}
                                minDate={dayjs('1940-01-01')}
                                onChange={(newValue) => {
                                    props.handleBirthdayChange(newValue?.toISOString());
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <FormTextField
                    defaultValue={props.employee?.onPremisesExtensionAttributes?.extensionAttribute2}
                    label="JMBG"
                    entityName="ucin"
                    onChange={props.handleUcinChange}
                />
                <FormTextField
                    defaultValue={props.employee?.onPremisesExtensionAttributes?.extensionAttribute1}
                    label="Br. licne karte"
                    entityName="personalNumber"
                    onChange={props.handlePerNumChange}
                />
            </Grid>
        </>
    );
}
