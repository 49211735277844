import { CircularProgress } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import './LoaderProgress.css';

export function LoaderProgress(): ReactElement {
    return (
        <div className="loader-container">
            <CircularProgress disableShrink className="loader" />
        </div>
    );
}
