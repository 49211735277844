import React, { ReactElement, useEffect, useState } from 'react';
import user_logo from '../../assets/img/user_logo.png';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './PersonCardTemplate.css';
import { Grid, Typography } from '@mui/material';
import { GraphClient } from '../../api/MSGraphExtensions';
import PersonCardTemplateTabs from './PersonCardTemplateTabs';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BusinessIcon from '@mui/icons-material/Business';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import { b64toBlob, getBase64 } from '../../Extensions/Utills';
import { removePhotosFromCache } from '../../Extensions/CacheHandler';
import { CompleteUserInfoDto } from '../../Extensions/UserOnCreation';

const ICON_SIZE = 16;
const SLIGHTLY_GRAY = '#888';
const THIN_FONT = 10;

type PersonCardTemplateProps = {
    person?: CompleteUserInfoDto;
    personImage?: string;
};

export default function PersonCardTemplate({ person, personImage }: PersonCardTemplateProps): ReactElement {
    const [hovered, setHovered] = useState<boolean>(false);
    const [profileImg, setProfileImg] = useState<File | undefined>(undefined);

    useEffect(() => {
        if (profileImg) {
            const img = profileImg;
            if (img && person?.id) {
                removePhotosFromCache();
                getBase64(img).then((b64Img) => {
                    const imgB = b64toBlob(b64Img as string);
                    GraphClient.updateUserProfilePhoto(person?.id ?? 'error', imgB);
                });
            }
        }
    }, [profileImg]);

    const handleProfileImageChange = (selectorFiles: FileList | undefined): void => {
        const filesArray = selectorFiles !== undefined ? selectorFiles[0] : undefined;
        if (filesArray !== undefined) {
            setProfileImg(filesArray);
        }
    };

    const personInfoTabElementItem = (icon: JSX.Element, text1?: string, text2?: string): ReactElement => {
        return text1 && text2 && text1 != '' && text2 != '' ? (
            <div
                style={{
                    display: 'inline-flex',
                    verticalAlign: 'center',
                    height: '100%',
                    width: 270,
                    margin: 'auto',
                }}
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{ margin: 'auto', width: '100%' }}
                >
                    <Grid item>{icon}</Grid>
                    <Grid item style={{ width: 210 }}>
                        <Typography
                            variant="subtitle2"
                            style={{
                                margin: 0,
                                color: SLIGHTLY_GRAY,
                                fontWeight: THIN_FONT,
                                textAlign: 'start',
                                fontSize: 12,
                            }}
                        >
                            {text1}
                        </Typography>
                        <Typography variant="body1" style={{ margin: 0, textAlign: 'start', fontSize: 13 }}>
                            {text2 ?? ''}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        ) : text1 && (!text2 || text2 == '') && text1 !== '' ? (
            <div
                style={{
                    display: 'inline-flex',
                    verticalAlign: 'center',
                    height: '100%',
                    width: 270,
                    margin: 'auto',
                }}
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={3}
                    style={{ margin: 'auto', width: '100%' }}
                >
                    <Grid item>{icon}</Grid>
                    <Grid item style={{ width: 210 }}>
                        <Typography variant="body1" style={{ margin: 0, textAlign: 'start', fontSize: 13 }}>
                            {text1 ?? ''}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        ) : (
            <></>
        );
    };

    const personInfoTabElement = (): ReactElement => {
        if (!person) {
            return <></>;
        }
        return (
            <Grid
                style={{
                    marginTop: -15,
                    maxHeight: 315,
                    overflowY: 'auto',
                }}
                container
                spacing={1}
            >
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        style={{ marginLeft: 12, textAlign: 'start', fontSize: 13, fontWeight: 600, color: '#565656' }}
                    >
                        O radniku
                    </Typography>
                </Grid>
                {person.givenName && person.givenName != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <PersonOutlineOutlinedIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Ime',
                            person.givenName,
                        )}
                    </Grid>
                )}
                {person.surname && person.surname != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <PersonOutlineOutlinedIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Prezime',
                            person.surname,
                        )}
                    </Grid>
                )}
                {person.userPrincipalName && person.userPrincipalName != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <PersonOutlineOutlinedIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Poslovno ime',
                            person.userPrincipalName.substr(0, person.userPrincipalName.indexOf('@')),
                        )}
                    </Grid>
                )}
                {person.country && person.country != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <BusinessIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Drzava',
                            person.country,
                        )}
                    </Grid>
                )}
                {person.city && person.city != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <BusinessIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Grad',
                            person.city,
                        )}
                    </Grid>
                )}
                {person.streetAddress && person.streetAddress != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <BusinessIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Licna adresa',
                            person.streetAddress,
                        )}
                    </Grid>
                )}
                {person.mobilePhone && person.mobilePhone != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <LocalPhoneIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Licni telefon',
                            person.mobilePhone,
                        )}
                    </Grid>
                )}
                {person.onPremisesExtensionAttributes?.extensionAttribute3 &&
                    person.onPremisesExtensionAttributes?.extensionAttribute3 != '' && (
                        <Grid item xs={12} xl={6}>
                            {personInfoTabElementItem(
                                <LocalPhoneIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                                'Datum rodjenja',
                                new Date(person.onPremisesExtensionAttributes?.extensionAttribute3).toLocaleDateString(
                                    'en-UK',
                                ),
                            )}
                        </Grid>
                    )}
                {person.onPremisesExtensionAttributes?.extensionAttribute1 &&
                    person.onPremisesExtensionAttributes?.extensionAttribute1 != '' && (
                        <Grid item xs={12} xl={6}>
                            {personInfoTabElementItem(
                                <LocalPhoneIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                                'Br. licne karte',
                                person.onPremisesExtensionAttributes?.extensionAttribute1,
                            )}
                        </Grid>
                    )}
                {person.onPremisesExtensionAttributes?.extensionAttribute2 &&
                    person.onPremisesExtensionAttributes?.extensionAttribute2 != '' && (
                        <Grid item xs={12} xl={6}>
                            {personInfoTabElementItem(
                                <LocalPhoneIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                                'JMBG',
                                person.onPremisesExtensionAttributes?.extensionAttribute2,
                            )}
                        </Grid>
                    )}
            </Grid>
        );
    };

    const personContactTabElement = () => {
        if (!person) {
            return <></>;
        }
        return (
            <Grid
                style={{
                    marginTop: -15,
                    maxHeight: 315,
                    overflowY: 'auto',
                }}
                container
                spacing={1}
            >
                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        style={{ marginLeft: 12, textAlign: 'start', fontSize: 13, fontWeight: 600, color: '#565656' }}
                    >
                        Kontakt
                    </Typography>
                </Grid>
                {person.mail && person.mail != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <EmailOutlinedIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Email',
                            person.mail,
                        )}
                    </Grid>
                )}
                {person.businessPhones && person.businessPhones.length > 0 && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <LocalPhoneIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Poslovni telefon',
                            person.businessPhones ? person.businessPhones[0] : '',
                        )}
                    </Grid>
                )}
                {person.officeLocation && person.officeLocation != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <BusinessIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Adresa kancelarije',
                            person.officeLocation,
                        )}
                    </Grid>
                )}
                {person.companyName && person.companyName != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <BusinessIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Kompanija',
                            person.companyName,
                        )}
                    </Grid>
                )}
                {person.department && person.department != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <GroupsIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Departman',
                            person.department,
                        )}
                    </Grid>
                )}
                {person.jobTitle && person.jobTitle != '' && (
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem(
                            <PersonOutlineOutlinedIcon style={{ color: SLIGHTLY_GRAY, fontSize: ICON_SIZE }} />,
                            'Radno mesto',
                            person.jobTitle,
                        )}
                    </Grid>
                )}
                {/* <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem()}
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem()}
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem()}
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem()}
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem()}
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem()}
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem()}
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem()}
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        {personInfoTabElementItem()}
                    </Grid> */}
            </Grid>
        );
    };

    return (
        <div>
            <div className="basic-info-container">
                <label className="basic-info-img-container">
                    <div
                        className="profile-img-container"
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => {
                            setHovered(false);
                        }}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            multiple={false}
                            onChange={(e) => handleProfileImageChange(e.target.files as FileList)}
                            hidden
                        />
                        <div
                            className={hovered ? 'profile-img-dark-cover' : 'display-none'}
                            style={{ zIndex: 7 }}
                        ></div>
                        <div className={'profile-img-icon'} style={{ zIndex: 7 }}>
                            <AddCircleIcon />
                        </div>
                        <img className="profile-img" src={personImage ?? user_logo} alt="photo" />
                    </div>
                </label>
                <div className="basic-info-text-container">
                    <Typography component="p" variant="h5" style={{ display: 'inline', color: '#555' }}>
                        {person?.displayName}
                    </Typography>
                    <Typography component="p" variant="body1" style={{ display: 'inline', color: '#666' }}>
                        ({person?.userPrincipalName?.substr(0, person?.userPrincipalName?.indexOf('@'))})
                    </Typography>
                    <Typography component="p" variant="subtitle1" style={{ color: '#888' }}>
                        {person?.jobTitle?.toUpperCase()}
                    </Typography>
                    <Typography component="p" variant="caption" style={{ color: '#888' }}>
                        {person?.department}
                    </Typography>
                </div>
            </div>
            <div className="additional-info-container">
                <PersonCardTemplateTabs
                    personInfoTabElement={personInfoTabElement()}
                    personCotnactTabElement={personContactTabElement()}
                />
            </div>
        </div>
    );
}
