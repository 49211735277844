import { JobPositionType } from '../api/clientApi';
import { NameDataSetPair, NameDataPair } from './Common';

export enum Departments {
    Armature,
    Transport,
}

export enum JobPositions {
    ArmatureOperator = 'Tehnicka podrska',
    ArmatureWorker = 'Operator masina',
    Driver = 'Vozac',
    TransportOperator = 'Transport operater',
}

export const JobPositionGroupId: NameDataPair[] = [
    {
        name: JobPositions.ArmatureOperator,
        data: 'e78c479d-d677-43c9-a187-90b85b395f7a',
    },
    {
        name: JobPositions.ArmatureWorker,
        data: '5e3352e1-5578-4f17-9660-0d522055aae4',
    },
    {
        name: JobPositions.Driver,
        data: 'c08a08a3-2050-4371-b6ca-a22305da3a98',
    },
    {
        name: JobPositions.TransportOperator,
        data: '1a17619f-1d26-4f29-af3b-9c064677d7d5',
    },
];

export const DepartmentalJobTitles: NameDataSetPair[] = [
    {
        name: Departments[Departments.Armature],
        data: [JobPositions.ArmatureOperator, JobPositions.ArmatureWorker],
    },
    {
        name: Departments[Departments.Transport],
        data: [JobPositions.Driver, JobPositions.TransportOperator],
    },
];

export const JobPositionTypeToJobPositions: NameDataPair[] = [
    {
        name: JobPositionType.ArmatureOperator,
        data: JobPositions.ArmatureOperator,
    },
    {
        name: JobPositionType.ArmatureWorker,
        data: JobPositions.ArmatureWorker,
    },
    {
        name: JobPositionType.TransportOperator,
        data: JobPositions.TransportOperator,
    },
    {
        name: JobPositionType.Driver,
        data: JobPositions.Driver,
    },
];

const GRANITDOO = 'Granit DOO';

export enum Companies {
    GranitDOO,
}

export const CompanyNames: NameDataPair[] = [
    {
        name: Companies[Companies.GranitDOO],
        data: GRANITDOO,
    },
];

export const CompaniesUserPrincipal: NameDataPair[] = [
    {
        name: GRANITDOO,
        data: '@granitdoo.rs',
    },
];

export enum AppDisplayName {
    GranitHRPanel = 'GranitHRPanel',
    GranitAPI = 'Granit API',
    GranitArmClient = 'GranitArmClient',
    GranitArmClientInt = 'GranitArmClient_Int',
    TransportClient = 'Transport Client',
}

export enum RoleDisplayName {
    HRPanel_Employee = 'HRPanel_Employee',
    HRPanel_Admin = 'HRPanel_Admin',
    GranitAPI_ArmatureWorker = 'Armature Worker',
    GranitAPI_ArmatureOperator = 'Armature Operator',
    GranitAPI_Employee = 'Employee',
    GranitAPI_TransportOperator = 'Transport Operator',
    GranitAPI_CEO = 'CEO',
    GranitAPI_ArmatureAdmin = 'Armature Admin',
    GranitArmClient_ArmatureWorker = 'Armature Worker',
    GranitArmClient_ArmatureOperator = 'Armature Operator',
    GranitArmClientInt_ArmatureWorker = 'Armature Worker',
    GranitArmClientInt_ArmatureOperator = 'Armature Operator',
    TransportClient_Driver = 'Employee',
    TransportClient_TransportOperator = 'Transport Operator',
}

export const getRoleDisplayNameOfAppDisplayNameAndJT = (
    appDisplayName: AppDisplayName,
    jt: JobPositionType,
): RoleDisplayName | null => {
    switch (appDisplayName) {
        case AppDisplayName.GranitAPI:
            switch (jt) {
                case JobPositionType.ArmatureOperator:
                    return RoleDisplayName.GranitAPI_ArmatureOperator;
                case JobPositionType.ArmatureWorker:
                    return RoleDisplayName.GranitAPI_ArmatureWorker;
                case JobPositionType.Driver:
                    return RoleDisplayName.GranitAPI_Employee;
                case JobPositionType.TransportOperator:
                    return RoleDisplayName.GranitAPI_TransportOperator;
                default:
                    return null;
            }
        case AppDisplayName.GranitHRPanel:
            switch (jt) {
                case JobPositionType.ArmatureOperator:
                case JobPositionType.ArmatureWorker:
                case JobPositionType.Driver:
                case JobPositionType.TransportOperator:
                    return RoleDisplayName.HRPanel_Employee;
                default:
                    return null;
            }
        case AppDisplayName.GranitArmClient:
            switch (jt) {
                case JobPositionType.ArmatureOperator:
                    return RoleDisplayName.GranitArmClient_ArmatureOperator;
                case JobPositionType.ArmatureWorker:
                    return RoleDisplayName.GranitArmClient_ArmatureWorker;
                default:
                    return null;
            }
        case AppDisplayName.GranitArmClientInt:
            switch (jt) {
                case JobPositionType.ArmatureOperator:
                    return RoleDisplayName.GranitArmClientInt_ArmatureOperator;
                case JobPositionType.ArmatureWorker:
                    return RoleDisplayName.GranitArmClientInt_ArmatureWorker;
                default:
                    return null;
            }
        case AppDisplayName.TransportClient:
            switch (jt) {
                case JobPositionType.Driver:
                    return RoleDisplayName.TransportClient_Driver;
                case JobPositionType.TransportOperator:
                    return RoleDisplayName.TransportClient_TransportOperator;
                default:
                    return null;
            }
        default:
            return null;
    }
};

export const AppRoleRelation: NameDataSetPair[] = [
    {
        name: AppDisplayName.GranitAPI,
        data: [
            RoleDisplayName.GranitAPI_ArmatureAdmin,
            RoleDisplayName.GranitAPI_ArmatureOperator,
            RoleDisplayName.GranitAPI_CEO,
            RoleDisplayName.GranitAPI_Employee,
        ],
    },
    {
        name: AppDisplayName.GranitHRPanel,
        data: [RoleDisplayName.HRPanel_Admin, RoleDisplayName.HRPanel_Employee],
    },
    {
        name: AppDisplayName.GranitArmClient,
        data: [RoleDisplayName.GranitArmClient_ArmatureOperator, RoleDisplayName.GranitArmClient_ArmatureWorker],
    },
    {
        name: AppDisplayName.GranitArmClientInt,
        data: [RoleDisplayName.GranitArmClientInt_ArmatureOperator, RoleDisplayName.GranitArmClientInt_ArmatureWorker],
    },
    {
        name: AppDisplayName.GranitArmClientInt,
        data: [RoleDisplayName.GranitArmClient_ArmatureOperator, RoleDisplayName.GranitArmClient_ArmatureWorker],
    },
    {
        name: AppDisplayName.TransportClient,
        data: [RoleDisplayName.TransportClient_Driver, RoleDisplayName.TransportClient_TransportOperator],
    },
];

export const getJobPositionTypeOfJobPosition = (jp: string): JobPositionType | undefined => {
    switch (jp) {
        case JobPositions.ArmatureOperator:
            return JobPositionType.ArmatureOperator;
        case JobPositions.ArmatureWorker:
            return JobPositionType.ArmatureWorker;
        case JobPositions.Driver:
            return JobPositionType.Driver;
        case JobPositions.TransportOperator:
            return JobPositionType.TransportOperator;
        default:
            return undefined;
    }
};

export const getAppOfJobPostionType = (jobPositionType?: JobPositionType): Departments | null => {
    switch (jobPositionType) {
        case JobPositionType.Driver:
        case JobPositionType.TransportOperator:
            return Departments.Transport;
        case JobPositionType.ArmatureWorker:
        case JobPositionType.ArmatureOperator:
            return Departments.Armature;
        default:
            return null;
    }
};
