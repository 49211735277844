import { EmployeeInfoDto } from '../../api/clientApi';
import { ClientUserDto } from '../../api/models/ClientUserDto';
import { GraphUserDto, GraphUserManagerDto } from '../../api/msGraphApi';
import { ResponseState } from '../../Extensions/Types';
import { RootState } from '../reducers';
import { LoginStatus } from '../reducers/loggedEmployeeReducer';

export function selectEmployeeInfo(state: RootState): GraphUserDto | null {
    return state.loggedEmployee.basicEmployee;
}

export function logginUserStatus(state: RootState): LoginStatus | null {
    return state.loggedEmployee.logginStatus;
}

export function selectCurrentEmployeeInfo(state: RootState): ClientUserDto | null {
    return state.currentEmployee.currentEmployee;
}

export function selectCurrentEmployeeState(state: RootState): ResponseState | null {
    return state.currentEmployee.currentEmployeeState;
}

export function selectCurrentEmployeeExtendedInfo(state: RootState): EmployeeInfoDto | null {
    return state.currentEmployee.currEmployee;
}

export function selectCurrentEmployeeManagerChain(state: RootState): GraphUserManagerDto | null {
    return state.currentEmployee.managerChain;
}

export function selectCurrentEmployeeManagerChainState(state: RootState): ResponseState | null {
    return state.currentEmployee.managerChainState;
}

export function selectCurrentEmployeeDirectReports(state: RootState): ClientUserDto[] | null {
    return state.currentEmployee.directReports;
}

export function selectCurrentEmployeeDirectReportsState(state: RootState): ResponseState | null {
    return state.currentEmployee.directReportsState;
}
