import { createSlice } from '@reduxjs/toolkit';
import { PrivilegeType } from '../../Extensions/Types';

export interface UserPrivilegesState {
    userPrivilege: PrivilegeType;
}

const initialState: UserPrivilegesState = {
    userPrivilege: PrivilegeType.Guest,
};

export const privilegesSlice = createSlice({
    name: 'privileges',
    initialState,
    reducers: {
        setUserPrivilege(state, action) {
            state.userPrivilege = action.payload;
        },
    },
});

export const { setUserPrivilege } = privilegesSlice.actions;

export default privilegesSlice.reducer;
