/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
const logger = (store) => (next) => (action) => {
    //console.group(action.type)
    //console.info('dispatching', action)
    let result = next(action);
    //console.log('next state', store.getState())
    //console.groupEnd()
    return result;
};

export default logger;
