import { Alert, AlertTitle, Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { ReactElement } from 'react';
import { NameDataPair } from '../Extensions/Common';
import { copyTextToClipboard } from '../Extensions/Utills';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import './AlertWindow.css';

type AlertWindowProps = {
    close(): void;
    title: string;
    data: NameDataPair[];
    disclaimer?: string;
};

export default function AlertWindow(props: AlertWindowProps): ReactElement {
    const copyToClipboard = () => {
        let text = '';
        if (props.data && props.data.length > 0) {
            for (const d of props.data) {
                text += d.name + ': ' + d.data + '\n';
            }
        } else {
            text = 'greska';
        }

        copyTextToClipboard(text);
    };

    return (
        <Box className="alert-window-container">
            <Alert
                className="alert-container"
                icon={<ErrorOutlineOutlinedIcon style={{ fontSize: 28 }} />}
                variant="outlined"
                severity="error"
                style={{ padding: 20 }}
                action={
                    <div className="alert-close-btn" onClick={props.close}>
                        X
                    </div>
                }
            >
                <Grid container spacing={2}>
                    <Grid key="title" item xs={12} style={{ marginBottom: 20 }}>
                        <AlertTitle style={{ fontSize: 20 }}>{props.title}</AlertTitle>
                    </Grid>
                    {props.data.map((dat) => {
                        return (
                            <Grid key={dat.name} item xs={12}>
                                <Typography component="p" variant="body1" display="inline" color="rgba(95,33,32, 0.7)">
                                    {dat.name}:{' '}
                                </Typography>
                                <Typography component="p" variant="body1" display="inline">
                                    {dat.data}
                                </Typography>
                            </Grid>
                        );
                    })}
                    {props.data && props.data.length > 0 && (
                        <Grid key="copy" item xs={12}>
                            <Tooltip title="Kopiraj">
                                <IconButton onClick={copyToClipboard} color="primary">
                                    <ContentCopyIcon style={{ fontSize: 50 }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                {props.disclaimer ? (
                    <div className="alert-footer">
                        <Typography component="h6" variant="body2">
                            {props.disclaimer}
                        </Typography>
                    </div>
                ) : (
                    <></>
                )}
            </Alert>
        </Box>
    );
}
