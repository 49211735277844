export enum Environment {
    Dev = 'Dev',
    Int = 'Int',
    Ppe = 'Ppe',
    Prod = 'Prod',
}

const urlLocation: string = window.location.origin;

// because other environments but prod are StaticWebAppEnv, we will redirect to real deployment
if (urlLocation.startsWith('https://hrweb-int.granitdoo.rs')) {
    window.open(`'https://nice-flower-060323503-main.westeurope.2.azurestaticapps.net',·'_blank'`);
} else if (urlLocation.startsWith('https://hrweb-beta.granitdoo.rs')) {
    window.open(`'https://nice-flower-060323503-grtppe.westeurope.2.azurestaticapps.net',·'_blank'`);
}

export let ClientEnv: Environment = Environment.Dev;

if (urlLocation.startsWith('https://hrweb.granitdoo.rs')) {
    ClientEnv = Environment.Prod;
} else if (urlLocation.startsWith('https://nice-flower-060323503-grtppe.westeurope.2.azurestaticapps.net')) {
    ClientEnv = Environment.Ppe;
} else if (urlLocation.startsWith('https://nice-flower-060323503-main.westeurope.2.azurestaticapps.net')) {
    ClientEnv = Environment.Int;
} else {
    ClientEnv = Environment.Dev;
}

if (ClientEnv === Environment.Ppe) {
    document.title += ' - Beta';
} else if (ClientEnv === Environment.Int) {
    document.title += ' - Int';
} else if (ClientEnv === Environment.Dev) {
    document.title += ' - DEV';
}

export let armBaseUrl: string;
export let transportBaseUrl: string;

if (ClientEnv === Environment.Prod || ClientEnv === Environment.Ppe) {
    armBaseUrl = 'https://granitdooarm.azurewebsites.net';
    transportBaseUrl = 'https://granitdoo.azurewebsites.net';
} else if (ClientEnv === Environment.Int) {
    transportBaseUrl = 'https://ninegrt.azurewebsites.net';
    armBaseUrl = 'https://grtarmatureint.azurewebsites.net';
} else if (ClientEnv === Environment.Dev) {
    // armBaseUrl = transportBaseUrl = 'https://localhost:44365';
    transportBaseUrl = 'https://ninegrt.azurewebsites.net';
    armBaseUrl = 'https://grtarmatureint.azurewebsites.net';
} else {
    throw Error('Environment error');
}
