import {
    Grid,
    FormControl,
    Stack,
    TextField,
    InputLabel,
    MenuItem,
    Select,
    OutlinedInput,
    InputAdornment,
    Tooltip,
} from '@mui/material';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import { DepartmentalJobTitles } from '../../../Extensions/JPGroupRoleHelper';
import { ICompleteUserInfoDto } from '../../../Extensions/UserOnCreation';
import FormTextField from '../../Common/FormTextField';
import { SelectChangeEvent } from '@mui/material';
import { useEffect } from 'react';
import { GraphClient } from '../../../api/MSGraphExtensions';
import { GraphUserDto } from '../../../api/msGraphApi';
import { Person, ViewType } from '@microsoft/mgt-react';
import InfoIcon from '@mui/icons-material/Info';
import { useParams } from 'react-router-dom';

type EmployeeJobInfoProps = {
    employee: ICompleteUserInfoDto;
    managerId: string;
    userPrincCorrectness: boolean;
    setHireDate(date: string | undefined): void;
    changeBusinessPhone(event: ChangeEvent<HTMLInputElement>): void;
    handleInputElementChange(event: ChangeEvent<HTMLInputElement>): void;
    handleSelectElementChange(event: SelectChangeEvent): void;
    handleManagerChange(id: string): void;
    isUpdateForm: boolean;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function EmployeeJobInfo(props: EmployeeJobInfoProps): ReactElement {
    const [department, selectDepartment] = useState(props.employee.department ?? '');
    const [jobTitle, selectJobTitle] = useState(props.employee.jobTitle ?? '');
    const [managers, setManagers] = useState<GraphUserDto[]>([]);

    const { employeeId } = useParams();

    useEffect(() => {
        GraphClient.getAllUsers().then((res) => {
            setManagers(res);
        });
    }, []);

    const handleDepartmentSelect = (event: SelectChangeEvent): void => {
        props.handleSelectElementChange(event);

        selectDepartment(event.target.value);
        selectJobTitle('');
    };

    const handleJobTitleSelect = (event: SelectChangeEvent): void => {
        props.handleSelectElementChange(event);

        selectJobTitle(event.target.value);
    };

    return (
        <Grid container spacing={5}>
            {!props.isUpdateForm && (
                <Grid item xs={12} md={6}>
                    <TextField
                        error={!props.userPrincCorrectness}
                        name="mailNickname"
                        required={true}
                        className="col-l"
                        id="filled-basic"
                        label="Poslovno ime"
                        value={props.employee?.mailNickname}
                        variant="standard"
                        onChange={props.handleInputElementChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title="Ovo je Vase jedinstveno identifikaciono ime u firmi. Ovo polje mora sadrzati makar 3 karaktera; mora biti sacinjeno samo od spojenih slova">
                                        <InfoIcon />
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            )}

            <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sr">
                    <Stack spacing={3}>
                        <DesktopDatePicker
                            label="Datum zaposlenja"
                            disableMaskedInput
                            value={props.employee.employeeHireDate}
                            minDate={dayjs('1940-01-01')}
                            onChange={(newValue) => {
                                props.setHireDate(newValue?.toISOString());
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>
            </Grid>
            <FormTextField
                defaultValue={props.employee?.officeLocation}
                label="Adresa kancelarije"
                entityName="officeLocation"
                onChange={props.handleInputElementChange}
            />
            <FormTextField
                defaultValue={props.employee?.businessPhones ? props.employee?.businessPhones[0] : ''}
                label="Poslovni telefon"
                entityName="businessPhones"
                onChange={props.changeBusinessPhone}
            />
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Departman</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={department}
                        label="Departman"
                        name="department"
                        required={true}
                        error={department === ''}
                        onChange={handleDepartmentSelect}
                    >
                        {DepartmentalJobTitles.map((djt) => {
                            return (
                                <MenuItem key={djt.name} value={djt.name}>
                                    {djt.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Radno mesto</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={jobTitle}
                        label="JobTitle"
                        name="jobTitle"
                        required={true}
                        error={jobTitle === ''}
                        onChange={handleJobTitleSelect}
                    >
                        {DepartmentalJobTitles.find((djt) => djt.name === department)?.data?.map((dat) => {
                            return (
                                <MenuItem key={dat} value={dat}>
                                    {dat}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                    <InputLabel id="demo-multiple-name-label">Nadredjeni</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={props.managerId}
                        onChange={(e) => {
                            props.handleManagerChange(e.target.value);
                        }}
                        input={<OutlinedInput label="Nadredjeni" />}
                        MenuProps={MenuProps}
                    >
                        {managers.map(
                            (mg) =>
                                mg.id !== employeeId && (
                                    <MenuItem key={mg.id} value={mg.id}>
                                        <Person userId={mg.id} view={ViewType.oneline} line1Property={'displayName'} />
                                    </MenuItem>
                                ),
                        )}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
