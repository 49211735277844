import { GraphClient } from '../api/MSGraphExtensions';
import { setUserPrivilege } from '../redux/reducers/privilegesReducer';
import { PrivilegeType } from './Types';
import { AppDisplayName, RoleDisplayName } from './JPGroupRoleHelper';

export const checkForAdminPrivileges =
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    (userId: string | undefined) => (dispatch: (arg0: { payload: any; type: string }) => void) => {
        if (userId) {
            GraphClient.getServicePrincipals().then((res) => {
                const serPrincHRPanel = res.find((sp) => sp.appDisplayName === AppDisplayName.GranitHRPanel);
                if (serPrincHRPanel) {
                    if (serPrincHRPanel.appRoles && serPrincHRPanel.appRoles.length > 0) {
                        const adminAppRole = serPrincHRPanel.appRoles.find(
                            (r) => r.displayName == RoleDisplayName.HRPanel_Admin,
                        );

                        const employeeAppRole = serPrincHRPanel.appRoles.find(
                            (r) => r.displayName == RoleDisplayName.HRPanel_Employee,
                        );

                        if (adminAppRole && employeeAppRole) {
                            GraphClient.getUserAppRoles(userId).then((res) => {
                                const isAdmin = res.find((ar) => ar.appRoleId === adminAppRole.id) !== undefined;
                                const isEmployee = res.find((ar) => ar.appRoleId === employeeAppRole.id) !== undefined;

                                dispatch(
                                    setUserPrivilege(
                                        isAdmin
                                            ? PrivilegeType.Admin
                                            : isEmployee
                                            ? PrivilegeType.Employee
                                            : PrivilegeType.Guest,
                                    ),
                                );
                            });
                        }
                    }
                }
            });
        }
    };
