export const getPhotoUrl = (imgUrl: Blob | MediaSource): string => {
    if (imgUrl) {
        const urlObject = URL.createObjectURL(imgUrl);
        return urlObject;
    }

    return '';
};

export type NameDataPair = {
    name: string;
    data?: string;
};

export type NameDataSetPair = {
    name: string;
    data?: string[];
};

export type MenuOptions = {
    title: string;
    icon: JSX.Element;
    func(): void;
};

export enum AlertSeverity {
    error = 'error',
    success = 'success',
    info = 'info',
    warning = 'warning',
}

export type AlertData = {
    id?: string;
    title: string;
    type: AlertSeverity;
};

export const UPDATE_FORM_PATH = '/update_form';
