import { Typography } from '@mui/material';
import React from 'react';
import { ReactElement } from 'react';
import { NameDataPair } from '../../Extensions/Common';

type FormDataReviewProps = {
    displayText: NameDataPair[];
};

export default function FormDataReview(props: FormDataReviewProps): ReactElement {
    return (
        <>
            {props.displayText.map((dt) => {
                return (
                    <Typography key={dt.name} component="p">
                        {dt.name + ' ' + dt.data}
                    </Typography>
                );
            })}
        </>
    );
}
