import * as React from 'react';
import { ReactElement, ChangeEvent, useState, useEffect } from 'react';
import '../Forms.css';
import EmployeeJobInfo from './Pages/EmployeeJobInfo';
import EmployeeInfo from './Pages/EmployeeInfo';
import FormTemplate from '../Common/FormTemplate';
import FormDataReview from '../Common/FormDataReview';
import { useAppDispatch } from '../../hooks/hooks';
import { CompleteUserInfoDto, ICompleteUserInfoDto, IUserOnCreationDto } from '../../Extensions/UserOnCreation';
import {
    Companies,
    CompaniesUserPrincipal,
    CompanyNames,
    getJobPositionTypeOfJobPosition,
} from '../../Extensions/JPGroupRoleHelper';
import { AlertSeverity, NameDataPair, UPDATE_FORM_PATH } from '../../Extensions/Common';
import { GraphClient } from '../../api/MSGraphExtensions';
import { FormActionType, ResponseState } from '../../Extensions/Types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal, SelectChangeEvent } from '@mui/material';
import AlertWindow from '../../Common/AlertWindow';
import { addAlert } from '../../redux/reducers/alertReducer';
import { getAlertMessageForForm, getGUID } from '../../Extensions/Utills';
import {
    addMgr,
    addEmpToGroup,
    assignAppRoleToEmployee,
    removeEmpFromGroup,
    changeEmployeeAppsRoles,
    addEmployeeOB,
    addJobPositionOB,
    deleteJobPositionOB,
} from './EmployeeFormHelper';
import { removeUsersFromCache } from '../../Extensions/CacheHandler';
import { EmployeeAccountDto, EmployeeDto } from '../../api/clientApi';
import { GranitTransportVNextClient } from '../../api/Extension';

const steps = ['Osnovni podaci', 'Radno mesto', 'Pregled unetih informacija'];

export default function AddEmployeeForm(): ReactElement {
    const [activeStep, setActiveStep] = useState(0);
    const [newEmployeeInfoAlert, setNewEmployeeInfoAlert] = useState<boolean>(false);
    const [alertData, setAlertData] = useState<NameDataPair[]>([]);

    const [emp, setEmp] = useState<ICompleteUserInfoDto | undefined>(undefined);
    const [currEmployee, setCurrEmployee] = useState<ICompleteUserInfoDto>(new CompleteUserInfoDto());

    const [managerId, setManagerId] = useState<string>('');
    const [oldManagerId, setOldManagerId] = useState<string>('');
    const [userPrincCorrectness, setUserPrincCorrectness] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;

    const { employeeId } = useParams();

    useEffect(() => {
        checkUserPrincipalAvailability(
            currEmployee.mailNickname ?? '',
            CompaniesUserPrincipal.find(
                (cup) => cup.name === CompanyNames.find((cn) => cn.name === Companies[Companies.GranitDOO])?.data,
            )?.data,
        );
    }, [currEmployee.mailNickname]);

    useEffect(() => {
        if (path.endsWith(UPDATE_FORM_PATH)) {
            GraphClient.getUserCompleteInfo(employeeId ?? 'error').then((res) => {
                setEmp(res);
                setCurrEmployee(res);
                setShowForm(true);
                GraphClient.getUsersManager(res.id ?? 'error').then((res) => {
                    setManagerId(res.id ?? '');
                    setOldManagerId(res.id ?? '');
                });
            });
        } else {
            const compN = CompanyNames.find((cn) => cn.name == Companies[Companies.GranitDOO])?.data ?? '';
            const newEmp = new CompleteUserInfoDto();
            newEmp.companyName = compN;
            setCurrEmployee(newEmp);
            setShowForm(true);
            const newEmpOB = new EmployeeDto();
            newEmpOB.name = 'name';
            newEmpOB.address = 'address';
        }
    }, [path]);

    const openNewEmployeeInfoAlert = (data: NameDataPair[]) => {
        setAlertData(data);
        setNewEmployeeInfoAlert(true);
    };

    const closeNewEmployeeInfoAlert = () => {
        setNewEmployeeInfoAlert(false);
        navigate('/');
    };

    const handleInputElementChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setCurrEmployee({ ...currEmployee, [name]: value });
    };

    const handleSelectElementChange = (event: SelectChangeEvent): void => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setCurrEmployee({ ...currEmployee, [name]: value });
    };

    const handleManagerChange = (id: string): void => {
        setManagerId(id);
    };

    const handleBusinessPhoneChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const businessPhone = event.target.value;
        const bPhones = [businessPhone];
        setCurrEmployee({ ...currEmployee, businessPhones: bPhones });
    };

    const handleEmployeeHireDateChange = (date: string): void => {
        setCurrEmployee({ ...currEmployee, employeeHireDate: date });
    };

    const handleEmployeeBirthdayChange = (date?: string): void => {
        if (date) {
            setCurrEmployee((prevState) => {
                return {
                    ...prevState,
                    onPremisesExtensionAttributes: {
                        ...prevState.onPremisesExtensionAttributes,
                        extensionAttribute1: prevState.onPremisesExtensionAttributes?.extensionAttribute1,
                        extensionAttribute2: prevState.onPremisesExtensionAttributes?.extensionAttribute2,
                        extensionAttribute3: date,
                    },
                };
            });
        }
    };

    const handleEmployeeUcinChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const currUcin = event.target.value;
        setCurrEmployee((prevState) => {
            return {
                ...prevState,
                onPremisesExtensionAttributes: {
                    ...prevState.onPremisesExtensionAttributes,
                    extensionAttribute1: prevState.onPremisesExtensionAttributes?.extensionAttribute1,
                    extensionAttribute2: currUcin,
                    extensionAttribute3: prevState.onPremisesExtensionAttributes?.extensionAttribute3,
                },
            };
        });
    };

    const handleEmployeePersonalNumberChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const perNum = event.target.value;
        setCurrEmployee((prevState) => {
            return {
                ...prevState,
                onPremisesExtensionAttributes: {
                    ...prevState.onPremisesExtensionAttributes,
                    extensionAttribute1: perNum,
                    extensionAttribute2: prevState.onPremisesExtensionAttributes?.extensionAttribute2,
                    extensionAttribute3: prevState.onPremisesExtensionAttributes?.extensionAttribute3,
                },
            };
        });
    };

    const checkUserPrincipalAvailability = (userPrincipal: string, companyExt: string | undefined) => {
        const onlyLetters = !/[^a-z]/i.test(userPrincipal);
        const validLength = userPrincipal.length >= 3;

        const newPrincipal = userPrincipal + (companyExt ?? '');

        const isUpdateEmployee = emp ? emp.userPrincipalName === newPrincipal : false;

        onlyLetters && validLength
            ? GraphClient.checkUserPrincipalAvailability(newPrincipal).then((res) => {
                  setUserPrincCorrectness(res || isUpdateEmployee);
              })
            : setUserPrincCorrectness(false);
    };

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const addEmployeeToOB = (userAzureId: string) => {
        GraphClient.getUserById(userAzureId).then(
            (res) => {
                if (res.id) {
                    const empAcc: EmployeeAccountDto = new EmployeeAccountDto();
                    empAcc.username = res.userPrincipalName;
                    empAcc.password = getGUID();

                    const eOB = new EmployeeDto();
                    eOB.azObjId = res.id;
                    eOB.address = 'address';
                    eOB.name = 'name';
                    eOB.personalNumber = 'personalNumber';
                    eOB.ucin = 'ucin';
                    eOB.birthDate = new Date();

                    if (res.jobTitle && res.jobTitle !== '') {
                        const jp = getJobPositionTypeOfJobPosition(res.jobTitle);

                        if (jp) {
                            dispatch(addEmployeeOB(eOB, empAcc, jp));
                        } else {
                            dispatch(addEmployeeOB(eOB, empAcc));
                        }
                    } else {
                        dispatch(addEmployeeOB(eOB, empAcc));
                    }
                }
            },
            () => {
                const alert = getAlertMessageForForm(FormActionType.Add, 'radnika', AlertSeverity.error);
                dispatch(addAlert(alert));
            },
        );
    };

    const addNewEmployee = (user: any, managerId: string) => {
        GraphClient.addNewUser(user).then(
            (res) => {
                const dat: NameDataPair[] = [
                    { name: 'Korisnicko ime', data: user.userPrincipalName },
                    { name: 'Sifra', data: user.passwordProfile.password },
                ];

                const alert = getAlertMessageForForm(FormActionType.Add, 'radnika', AlertSeverity.success);
                dispatch(addAlert(alert));

                openNewEmployeeInfoAlert(dat);
                if (res.id) {
                    const empAcc: EmployeeAccountDto = new EmployeeAccountDto();
                    empAcc.username = res.userPrincipalName;
                    empAcc.password = getGUID();

                    addMgr(res.id, managerId);
                    const eOB = new EmployeeDto();
                    eOB.azObjId = res.id;
                    eOB.address = 'address';
                    eOB.name = 'name';
                    eOB.personalNumber = 'personalNumber';
                    eOB.ucin = 'ucin';
                    eOB.birthDate = new Date();

                    if (res.jobTitle && res.jobTitle !== '') {
                        addEmpToGroup(res.jobTitle, res.id);

                        const jp = getJobPositionTypeOfJobPosition(res.jobTitle);

                        if (jp) {
                            dispatch(addEmployeeOB(eOB, empAcc, jp));
                            assignAppRoleToEmployee(res.id, jp);
                        } else {
                            dispatch(addEmployeeOB(eOB, empAcc));
                        }
                    } else {
                        dispatch(addEmployeeOB(eOB, empAcc));
                    }
                }
            },
            () => {
                const alert = getAlertMessageForForm(FormActionType.Add, 'radnika', AlertSeverity.error);
                dispatch(addAlert(alert));
            },
        );
    };

    const addUser = () => {
        const userPrincipal = CompaniesUserPrincipal.find((cup) => cup.name === currEmployee.companyName)?.data;

        const user: IUserOnCreationDto = {
            accountEnabled: true,
            displayName: currEmployee.givenName + ' ' + currEmployee.surname,
            givenName: currEmployee.givenName,
            surname: currEmployee.surname,
            mailNickname: currEmployee.mailNickname,
            userPrincipalName: currEmployee.mailNickname + (userPrincipal ?? ''),
            passwordProfile: {
                forceChangePasswordNextSignIn: true,
                password: (currEmployee.companyName ?? '') + new Date().getFullYear() + '!',
            },
            businessPhones: currEmployee.businessPhones,
            city: currEmployee.city,
            companyName: currEmployee.companyName,
            country: currEmployee.country,
            department: currEmployee.department,
            jobTitle: currEmployee.jobTitle,
            mail: currEmployee.mail,
            mobilePhone: currEmployee.mobilePhone,
            officeLocation: currEmployee.officeLocation,
            streetAddress: currEmployee.streetAddress,
            employeeHireDate: currEmployee.employeeHireDate,
            onPremisesExtensionAttributes: currEmployee.onPremisesExtensionAttributes,
        };

        addNewEmployee(user, managerId);
    };

    const updateUser = () => {
        const userPrincipal = CompaniesUserPrincipal.find((cup) => cup.name === currEmployee.companyName)?.data;
        const user = currEmployee;
        user.displayName = currEmployee.givenName + ' ' + currEmployee.surname;
        user.userPrincipalName = currEmployee.mailNickname + (userPrincipal ?? '');
        user.onPremisesExtensionAttributes = currEmployee.onPremisesExtensionAttributes;
        if (employeeId && employeeId != '') {
            if (user && checkIfUsersAreDifferent()) {
                GraphClient.updateUser(employeeId, user).then(
                    (res) => {
                        if (res == ResponseState.Success) {
                            GranitTransportVNextClient.getEmployeeByAzureObjId(user.id, false, false, false).then(
                                (res) => {
                                    if (!res) {
                                        addEmployeeToOB(user.id ?? 'error');
                                    }
                                },
                            );

                            const alert = getAlertMessageForForm(
                                FormActionType.Edit,
                                'podatke o radniku',
                                AlertSeverity.success,
                            );
                            dispatch(addAlert(alert));
                        }
                    },
                    () => {
                        const alert = getAlertMessageForForm(
                            FormActionType.Edit,
                            'podataka o radniku',
                            AlertSeverity.error,
                        );
                        dispatch(addAlert(alert));
                    },
                );
            }

            if (user.id && emp?.jobTitle && user.jobTitle && user.jobTitle !== emp?.jobTitle) {
                const oldJp = getJobPositionTypeOfJobPosition(emp.jobTitle);
                const newJp = getJobPositionTypeOfJobPosition(user.jobTitle);

                if (newJp !== oldJp) {
                    if (oldJp && newJp) {
                        deleteJobPositionOB(user.id, oldJp, oldJp);
                        addJobPositionOB(user.id, newJp);
                        removeEmpFromGroup(emp.jobTitle, user.id);
                        changeEmployeeAppsRoles(user.id, oldJp, newJp);
                        addEmpToGroup(user.jobTitle, user.id);
                    }

                    if (newJp && !oldJp) {
                        addJobPositionOB(user.id, newJp);
                        addEmpToGroup(user.jobTitle, user.id);
                        assignAppRoleToEmployee(user.id, newJp);
                    }
                }
            }

            if (managerId && managerId != '' && managerId !== oldManagerId) {
                addMgr(employeeId, managerId);
            }
        }
    };

    const onSubmit = (): void => {
        if (path.endsWith('/add_form')) {
            addUser();
        } else if (path.endsWith(UPDATE_FORM_PATH)) {
            removeUsersFromCache();
            updateUser();
            navigate('../../employee/' + employeeId);
        }
    };

    const checkIfRequiredIsEmpty = (): boolean => {
        return currEmployee?.givenName &&
            currEmployee?.surname &&
            currEmployee?.jobTitle &&
            currEmployee?.department &&
            currEmployee?.mailNickname &&
            userPrincCorrectness
            ? false
            : true;
    };

    const checkIfUsersAreDifferent = (): boolean => {
        return emp?.givenName !== currEmployee?.givenName ||
            emp?.surname !== currEmployee?.surname ||
            emp?.city !== currEmployee?.city ||
            emp?.streetAddress !== currEmployee?.streetAddress ||
            emp?.officeLocation !== currEmployee?.officeLocation ||
            emp?.mobilePhone !== currEmployee?.mobilePhone ||
            emp?.mail !== currEmployee?.mail ||
            emp?.country !== currEmployee?.country ||
            emp?.jobTitle !== currEmployee?.jobTitle ||
            emp?.businessPhones !== currEmployee?.businessPhones ||
            emp?.department !== currEmployee?.department ||
            emp?.onPremisesExtensionAttributes !== currEmployee?.onPremisesExtensionAttributes
            ? true
            : false;
    };

    const isRequiredEmpty = checkIfRequiredIsEmpty();

    const shouldDisable = isRequiredEmpty && activeStep === 1;

    const hireDate = currEmployee.employeeHireDate ? new Date(currEmployee.employeeHireDate.slice(0, 10)) : undefined;

    const employeeText: NameDataPair[] = [
        { name: 'Ime:', data: currEmployee?.givenName ?? '' },
        { name: 'Prezime:', data: currEmployee?.surname ?? '' },
        { name: 'E-mail:', data: currEmployee?.mail ?? '' },
        { name: 'Licni telefon:', data: currEmployee?.mobilePhone ?? '' },
        { name: 'Licna adresa:', data: currEmployee?.streetAddress ?? '' },
        { name: 'Grad:', data: currEmployee?.city ?? '' },
        { name: 'Drzava:', data: currEmployee?.country ?? '' },
        {
            name: 'Datum rodjenja:',
            data: currEmployee.onPremisesExtensionAttributes?.extensionAttribute3
                ? new Date(currEmployee.onPremisesExtensionAttributes?.extensionAttribute3).toLocaleDateString('en-UK')
                : '',
        },
        { name: 'JMBG:', data: currEmployee.onPremisesExtensionAttributes?.extensionAttribute2 ?? '' },
        { name: 'Br. licne karte:', data: currEmployee.onPremisesExtensionAttributes?.extensionAttribute1 ?? '' },
        { name: 'Poslovno ime:', data: currEmployee?.mailNickname ?? '' },
        { name: 'Naziv kompanije:', data: currEmployee?.companyName ?? '' },
        {
            name: 'Datum zaposlenja:',
            data: hireDate ? hireDate.toLocaleDateString() : '',
        },
        { name: 'Adresa kancelarije:', data: currEmployee?.officeLocation ?? '' },
        { name: 'Poslovni telefon:', data: currEmployee?.businessPhones ? currEmployee?.businessPhones[0] ?? '' : '' },
        { name: 'Departman:', data: currEmployee?.department ?? '' },
        { name: 'Pozicija:', data: currEmployee?.jobTitle ?? '' },
    ];

    const getStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <EmployeeInfo
                        employee={currEmployee}
                        isUpdateForm={path.endsWith(UPDATE_FORM_PATH)}
                        handleInputElementChange={handleInputElementChange}
                        handleUcinChange={handleEmployeeUcinChange}
                        handlePerNumChange={handleEmployeePersonalNumberChange}
                        handleBirthdayChange={handleEmployeeBirthdayChange}
                    />
                );

            case 1:
                return (
                    <EmployeeJobInfo
                        employee={currEmployee}
                        managerId={managerId}
                        userPrincCorrectness={userPrincCorrectness}
                        handleSelectElementChange={handleSelectElementChange}
                        setHireDate={handleEmployeeHireDateChange}
                        changeBusinessPhone={handleBusinessPhoneChange}
                        handleInputElementChange={handleInputElementChange}
                        handleManagerChange={handleManagerChange}
                        isUpdateForm={path.endsWith(UPDATE_FORM_PATH)}
                    />
                );
            case 2:
                return <FormDataReview displayText={employeeText} />;
            default:
                throw new Error('Unknown step');
        }
    };

    const formElem = getStepContent();

    return showForm ? (
        <>
            <FormTemplate
                activeStep={activeStep}
                formContent={formElem}
                title={'Forma: Radnik'}
                shouldDisable={shouldDisable}
                submit={onSubmit}
                steps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
                wideWindow={false}
            />
            <Modal
                open={newEmployeeInfoAlert}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AlertWindow
                    close={closeNewEmployeeInfoAlert}
                    data={alertData}
                    title="Bitne informacije o novom radniku!"
                    disclaimer={`Sacuvajte ove informacije pre zatvaranja prozora!!! Nakon zatvaranja ovog prozora necete imati pristup gore navedenim informacijama!`}
                />
            </Modal>
        </>
    ) : (
        <></>
    );
}
