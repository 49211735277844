/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { configureStore } from '@reduxjs/toolkit';
import monitorReducersEnhancer from './enhancers/monitorReducer';
import loggerMiddleware from './middleware/logger';
import rootReducer from './reducers';

const defaultStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(loggerMiddleware),
    enhancers: [monitorReducersEnhancer],
});

function configureAppStore(preloadedState: any) {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }).concat(loggerMiddleware),
        preloadedState,
        enhancers: [monitorReducersEnhancer],
    });

    return store;
}

export default configureAppStore;

export type AppDispatch = typeof defaultStore.dispatch;
