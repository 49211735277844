import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import AppLayout from '../Layouts/AppLayout';
import { ReactElement } from 'react';

export default function App(): ReactElement {
    return (
        <BrowserRouter>
            <AppLayout />
        </BrowserRouter>
    );
}
